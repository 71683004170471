import React, { useState } from "react"
import { useLocation } from "react-router-dom"
// COMPONENTS
import MlmButton from "../components/Mlm-Button/MlmButton"

// ICONS
import { Download } from "../images/drawer/light"
import { LinkIcon } from "../images/other"
import QuestionAns from "../components/faq/QuestionAns"

type Props = {}

const KeyToMarkets = (props: Props) => {
    // STATES
    const { state } = useLocation()

    // CONSTANTS
    let tabs: any = []
    if (state.downloads?.length > 0) {
        tabs.push("Downloads")
    }
    if (state.links?.length > 0) {
        tabs.push("Links")
    }
    if (state.comments?.length > 0) {
        tabs.push("Comments")
    }

    const [selectedTab, setSelectedTab] = useState(tabs[0])
    const [expanded, setExpanded] = useState()

    // CALLBACKS
    const handleChange = (id: any) => {
        setExpanded((pre) => (pre === id ? 0 : id))
    }

    const handleDownload = (pdfUrl) => {
        fetch(pdfUrl)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a blob URL for the PDF
                const url = window.URL.createObjectURL(blob)
                // Create an anchor element to trigger the download
                const a = document.createElement("a")
                a.href = url
                a.download = "your_pdf_file.pdf" // Set the desired file name
                // Trigger a click event on the anchor element
                a.click()
                // Clean up
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error("Error downloading PDF:", error)
            })
    }

    return (
        <div>
            <div className="screen600:mt-3 screen900:mt-9 container flex flex-col">
                {/* TITLE */}
                <p className="text-2xl screen800:text-[1.8rem] screen1000:text-[1.8rem] font-bold tracking-wide">
                    {state?.name}
                </p>

                {/* CONTENT CONTAINER */}
                <div className="flex flex-col pt-12 w-full">
                    {/* TABS */}
                    <div className="flex ml-8 lg:ml-20 space-x-[0.7rem]">
                        {tabs.map((tab, index) => {
                            return (
                                <div className="flex flex-col items-center justify-end relative">
                                    <MlmButton
                                        key={index}
                                        text={tab}
                                        className={`lg:min-w-[150px] rounded-br-none rounded-bl-none ${
                                            selectedTab === tab
                                                ? "bg-white text-cardText border !border-cardText hover:!text-cardText !border-b-0"
                                                : "bg-cardTextGr text-white"
                                        }`}
                                        callBack={() => setSelectedTab(tab)}
                                    />
                                    {tab === selectedTab && (
                                        <div className="bg-white absolute h-1 w-[97.6%] xl:w-[98.7%] -bottom-[2px] rounded-full"></div>
                                    )}
                                </div>
                            )
                        })}
                    </div>

                    {/* BODY */}
                    <div
                        className={`flex flex-col py-3 screen1200:!py-12 px-3 screen1200:!px-10 rounded-lg border !border-cardText bg-white ${
                            selectedTab === "Comments"
                                ? "space-y-0"
                                : "space-y-5"
                        }`}
                    >
                        {/* DOWNLOADS */}
                        {selectedTab === "Downloads" && (
                            <>
                                {state?.downloads?.map(
                                    (downloadItem, index) => {
                                        return (
                                            <>
                                                <a
                                                    href={
                                                        downloadItem?.download_url
                                                    }
                                                    target="_blank"
                                                >
                                                    {/* CARD */}
                                                    <div className="px-6 py-3 flex space-x-5 bg-cardText rounded-md hover:cursor-pointer hover:bg-mainhover transition-all ease-in-out duration-300">
                                                        {/* ICON */}
                                                        <img
                                                            src={Download}
                                                            alt="Download Icon"
                                                            className="object-contain w-6 h-6"
                                                        />
                                                        {/* CONTENT */}
                                                        <p className="font-montserrat font-medium text-white">
                                                            {
                                                                downloadItem?.title
                                                            }
                                                        </p>
                                                    </div>
                                                </a>
                                            </>
                                        )
                                    }
                                )}
                            </>
                        )}

                        {/* LINKS */}
                        {selectedTab === "Links" && (
                            <>
                                {state?.links?.map((link, index) => {
                                    return (
                                        <>
                                            {/* CARD */}
                                            <a href={link} target="_blank">
                                                <div className="px-6 py-3 flex space-x-5 bg-white text-cardText border !border-cardText rounded-md hover:cursor-pointer hover:!bg-cardText/5 transition-all ease-in-out duration-300">
                                                    {/* ICON */}
                                                    <img
                                                        src={LinkIcon}
                                                        alt="Link Icon"
                                                        className="object-contain w-6 h-6"
                                                    />
                                                    <p className="font-montserrat font-medium text-cardText overflow-hidden hover:!text-cardText">
                                                        {link}
                                                    </p>
                                                </div>
                                            </a>
                                        </>
                                    )
                                })}
                            </>
                        )}

                        {/* COMMENTS */}
                        {selectedTab === "Comments" && (
                            <>
                                {state?.comments?.map((commentItem, index) => {
                                    const comment = {
                                        id: index + 1,
                                        question: commentItem?.title,
                                        answer: commentItem?.body,
                                    }
                                    return (
                                        <>
                                            {/* CONTAINER */}
                                            <div id="" className="w-full">
                                                <QuestionAns
                                                    expanded={expanded}
                                                    faq={comment}
                                                    handleChange={handleChange}
                                                    key={index}
                                                    index={index}
                                                />
                                            </div>
                                        </>
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeyToMarkets
