import { Checkbox } from "@mui/material"

export default function CheckBoxes({
    selectedRow,
    setSelectedRow,
    total = 0,
    index,
    classes = "",
}): JSX.Element {
    const addOrRemoveNumber = (numToAddOrRemove: number, checked: boolean) => {
        const numbers = [...selectedRow]
        // const total = supportList ? supportList.total_objects : 0
        if (numToAddOrRemove === -1) {
            if (checked) {
                const array: any = [-1]
                for (var i = 0; i < total; i++) {
                    array.push(i)
                }
                return array
            }
            return []
        }

        const index = numbers.indexOf(numToAddOrRemove)
        if (index === -1) {
            numbers.push(numToAddOrRemove)
        } else {
            numbers.splice(index, 1)
        }
        if (numbers.length <= 1 && numbers[0] === -1) {
            return addOrRemoveNumber(-1, false)
        } else if (numbers[0] !== -1 && numbers.length === total) {
            return addOrRemoveNumber(-1, true)
        }

        return numbers
    }

    return (
        <div>
            <Checkbox
                className={`${classes}`}
                style={{ backgroundColor: "transparent" }}
                checked={
                    selectedRow.length > 0
                        ? selectedRow.indexOf(index) > -1
                        : false
                }
                onChange={(e: any) => {
                    const array = addOrRemoveNumber(index, e.target.checked)
                    setSelectedRow(array)
                }}
            />
        </div>
    )
}
