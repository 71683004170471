//STYLES
import "./index.css"

export default function PreLoader(): JSX.Element {
    return (
        <div id="preloader">
            <div id="loader"></div>
        </div>
    )
}
