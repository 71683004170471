export default function currentStorage(rememberMe?: boolean) {
    // const loggedInWithRememberMe =
    //     localStorage.getItem("accessToken") &&
    //     localStorage.getItem("refreshToken")

    const storage = localStorage
    // rememberMe === undefined
    //     ? //already loggedin so checking what storage
    //       loggedInWithRememberMe
    //         ? localStorage
    //         : sessionStorage
    //     : //first time login
    //     rememberMe
    //     ? localStorage
    //     : sessionStorage

    return storage
}
