//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    contractList: any
}

const initialState: State = {
    contractList: null,
}

const slice = createSlice({
    name: "contract",
    initialState,
    reducers: {
        setContractList: (state, action: PayloadAction<any>) => {
            return { ...state, contractList: action.payload }
        },
    },
})

export const { setContractList } = slice.actions

export const selectContract = (state: { contract: State }) => state.contract

export default slice.reducer
