//REACT
import React from "react"
import ReactDOM from "react-dom/client"
//REDUX-TOOLKIT
import { Provider } from "react-redux"
import { store } from "./store"
//ROUTER-DOM
import { RouterProvider } from "react-router-dom"
//UTILS
import { router } from "./utils/routes"
//STYLES
import "./styles/index.css"

const root = document.getElementById("root") as HTMLElement

ReactDOM.createRoot(root).render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
)
