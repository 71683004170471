//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import currentStorage from "../../utils/currentStorage"

const storage = currentStorage()

const country = storage.getItem("selectedCountry")

interface DrawerSliceState {
    width: number
    open: boolean
    openItemKey: string
    tab: string
    activeMenu?: string
    expendMenu?: boolean
    expendKey?: string
    selectedCountry?: string
}
const initialState: DrawerSliceState = {
    width: 240,
    open: false,
    openItemKey: "",
    tab: "/",
    activeMenu: "/",
    expendMenu: false,
    expendKey: "",
    selectedCountry: country ? country : "en_US",
}

const drawerSlice = createSlice({
    name: "drawerSlice",
    initialState,
    reducers: {
        setDrawerWidth: (state, action: PayloadAction<number>) => {
            state.width = action.payload
        },
        setDrawerOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload
        },
        setDrawerOpenItemIndex: (state, action: PayloadAction<string>) => {
            state.openItemKey = action.payload
        },
        setDrawerTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload
        },
        setDrawerCountry: (state, action: PayloadAction<string>) => {
            state.selectedCountry = action.payload
        },
        setActiveMenu: (state, action: PayloadAction<any>) => {
            let menu: any = action.payload
            return {
                ...state,
                activeMenu: menu.url,
            }
        },
        setExpendMenu: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                ...action.payload,
            }
        },
    },
})

export const {
    setDrawerWidth,
    setDrawerOpen,
    setDrawerOpenItemIndex,
    setDrawerTab,
    setDrawerCountry,
    setActiveMenu,
    setExpendMenu,
} = drawerSlice.actions
export const selectDrawerWidth = (state: { drawer: DrawerSliceState }) =>
    state.drawer.width
export const selectDrawerOpen = (state: { drawer: DrawerSliceState }) =>
    state.drawer.open
export const selectDrawerOpenItemKey_ = (state: { drawer: DrawerSliceState }) =>
    state.drawer.openItemKey
export const selectDrawerTab = (state: { drawer: DrawerSliceState }) =>
    state.drawer.tab
export const selectDrawerCountry = (state: { drawer: DrawerSliceState }) =>
    state.drawer.selectedCountry
export const selectActiveMenu = (state: { drawer: DrawerSliceState }) =>
    state.drawer.activeMenu

export const selectDrawer = (state: { drawer: DrawerSliceState }) =>
    state.drawer

export default drawerSlice.reducer
