//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    newsList: any[]
}

const initialState: State = {
    newsList: [],
}

const slice = createSlice({
    name: "news",
    initialState,
    reducers: {
        setNewsList: (state, action: PayloadAction<any>) => {
            return { ...state, newsList: action.payload }
        },
    },
})

export const { setNewsList } = slice.actions

export const selectNews = (state: { news: State }) => state.news

export default slice.reducer
