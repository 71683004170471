//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Tutorial } from "../../interfaces/interfaces"

interface State {
    list: Tutorial[]
}

const initialState: State = {
    list: [],
}

const slice = createSlice({
    name: "tutorial",
    initialState,
    reducers: {
        setTutorials: (state, action: PayloadAction<any>) => {
            return { ...state, list: action.payload }
        },
    },
})

export const { setTutorials } = slice.actions
export const selectTutorials = (state: { tutorial: State }) =>
    state.tutorial.list
export default slice.reducer
