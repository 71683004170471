import { CircularProgress } from "@mui/material"
import { exportsvg } from "../../images/other"

type Pros = {
    className?: string
    icon?: any | null
    iconContent?: any | null
    iconStyle?: string
    internalDivStyle?: string
    text: any
    callBack?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    disabled?: boolean
    loading?: boolean
    loadingType?: string
    onMouseEntered?: any
    onMouseLeave?: any
    hover?: any
    hoverIcon?: any
}

export default function MlmButton({
    className = "",
    icon = null,
    iconContent = null,
    iconStyle = "",
    internalDivStyle = "",
    text,
    callBack = (e) => {},
    loadingType = "circular",
    loading = false,
    disabled = false,
    onMouseEntered = (e: any) => {},
    onMouseLeave = (e: any) => {},
    hover = false,
    hoverIcon = null,
}: Pros): JSX.Element {
    return (
        <button
            className={`flex screen500:space-x-1 justify-center items-center screen500:px-1 screen500:py-1 rounded-md 
                    hover:bg-mainhover hover:text-white ${className} `}
            disabled={loading || disabled}
            onClick={callBack}
            onMouseEnter={onMouseEntered}
            onMouseLeave={onMouseLeave}
        >
            {loading ? (
                <>
                    {loadingType === "text" ? (
                        <span>Loading...</span>
                    ) : (
                        <CircularProgress size={20} color="inherit" />
                    )}
                </>
            ) : (
                <div
                    className={`flex items-center ${internalDivStyle} py-1 px-2 gap-2`}
                >
                    {icon ? (
                        // <>{icon}</>
                        <img
                            src={hover ? hoverIcon : icon}
                            alt="button icon"
                            className={`${iconStyle}`}
                        />
                    ) : (
                        iconContent
                    )}
                    {text && <p>{text}</p>}
                </div>
            )}
        </button>
    )
}
