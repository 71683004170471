import { useEffect, useState } from "react"
import { BtnClipboard, MlmButton, QR, SocialButton } from "../.."
import { downloadLightIcon, shareDiamondBg1 } from "../../../images"
import {
    closeGray,
    rankStatusDiamond3,
    rankStatusDiamond4,
} from "../../../images/other"
import EmailSection from "./StateHooks"
import { useShareActions } from "../../../store/shares/shareAction"
import { getRankCurrentWithNextIcons } from "../../../utils/utils"
import { successNotify } from "../../../utils/alerts"
import { createCapture } from "../../../utils/allConstants"

export function QRContent({ link, close }): JSX.Element {
    return (
        <div className="font-montserrat flex justify-center flex-col gap-3 h-full text-darker">
            {/* QR SECTION */}
            <div
                className="px-3 flex justify-center flex-col gap-3"
                id="qr-invitation"
            >
                <span className="text-[18px] font-semibold text-center text-darker">
                    Share The invitation
                </span>
                {qrSection(link, "Scan this QR code to earn points")}
            </div>
            {/* SOCIAL SHARE ICONS SECTION */}
            <div className="mt-[25px]">{socialSection(link)}</div>
            {/* COPY THE LINK BELOW */}
            {CopyDown(
                link,
                close,
                "Download",
                downloadLightIcon,
                false,
                (e) => {
                    createCapture({
                        id: "qr-invitation",
                        padding: "0 0 2.5rem 0",
                    })
                }
            )}
        </div>
    )
}

export function shareContent({ link, close }): JSX.Element {
    const { emails, setEmails, text, setText, getShare } = EmailSection()
    return (
        <div className="font-montserrat flex flex-col gap-3 h-[580px] w-[500px] text-darker">
            {/* invitation field SECTION */}
            <div className="flex flex-col gap-3">
                <span className="text-[18px] font-semibold text-darker">
                    Send the invitation!
                </span>
                <div className="mt-3">
                    {emailSection("Email", emails, setEmails)}
                </div>
            </div>
            {/* SOCIAL SHARE ICONS SECTION */}
            <div className="mt-3">{socialSection(link)}</div>
            {/* COPY THE LINK BELOW */}
            {CopyDown(
                link,
                close,
                "Send invite",
                null,
                true,
                async (e: any) => {
                    if (emails.length <= 0) return "There are no emails found."
                    const array = emails.map(
                        (item: any, index: number) => item.email
                    )
                    const share = await getShare({ emails: array })
                    if (!share.data.status) return share.data.data.message
                    setEmails([])
                    return share.data
                }
            )}
            <div className="mt-3 flex flex-col gap-2">
                <hr />
                <p className="text-secondary text-xs">
                    <span className="font-bold">Attention: </span>An official
                    invitation email will be sent to the user with the rules set
                    by your{" "}
                    <span className="underline font-normal">
                        Default Referral Link.
                    </span>
                    If you want o change the settings choose or generate a
                    different referral link.
                </p>
            </div>
        </div>
    )
}

export const tagChip = (data: any, removeCallBack: any) => {
    return (
        <div className="flex gap-2 items-center py-[2px] px-2 bg-aliceBlue rounded">
            <span className="text-secondary text-sm font-medium">
                {data.email}
            </span>
            <img
                src={closeGray}
                alt=""
                className="w-[15px] cursor-pointer"
                onClick={removeCallBack}
            />
        </div>
    )
}

export function emailSection(
    title = "Email",
    emails: any = null,
    setEmails: any = null
): JSX.Element {
    const { text, setText, validation, setValidation } = EmailSection()

    const handleChange = (event: any) => {
        if (!text) return setValidation("please enter email")
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const isValid = emailPattern.test(text)
        if (!isValid) return setValidation("email is not valid")
        if (!emails) return
        const obj = { email: text }
        setEmails([...emails, obj])
        setText("")
    }

    return (
        <div className="flex flex-col gap-2">
            <span className="text-sm font-medium capitalize">{title}:</span>
            <div className="flex gap-2 items-start ">
                <div className="w-full">
                    <div
                        className="bg-snow border-[1px] 
                border-grayscale p-2 rounded"
                    >
                        <input
                            placeholder="johndoe@gmail.com"
                            type="email"
                            id="txtEmail"
                            className="border-0 outline-none bg-transparent text-secondary w-full"
                            value={text}
                            onChange={(event) => {
                                setValidation("")
                                setText(event.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    e.preventDefault()
                                    handleChange(e)
                                }
                            }}
                            style={{
                                borderColor: validation ? "red" : "initial",
                            }}
                        />
                    </div>
                    {validation && <p style={{ color: "red" }}>{validation}</p>}
                </div>
                <MlmButton
                    text={"Add Email"}
                    className="text-inputborder bg-cardText rounded 
                    font-poppins text-sm font-normal py-2 px-1 w-[150px] h-full items-start"
                    callBack={handleChange}
                />
            </div>
            <div className="h-[60px] overflow-y-auto mt-3">
                <div className="flex flex-wrap gap-2">
                    {emails &&
                        emails.map((item: any, index: number) => (
                            <div key={index}>
                                {tagChip(item, () => {
                                    const updatedItems = [...emails]
                                    updatedItems.splice(index, 1)
                                    setEmails(updatedItems)
                                })}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export function qrSection(link: string, title: string): JSX.Element {
    const { user } = EmailSection()
    if (!user) return <div></div>
    const icons = getRankCurrentWithNextIcons(user.rank)
    return (
        <div
            className="bg-gradient-to-r from-main to-cardText rounded-lg
                    text-inputborder"
        >
            <div
                className="lg:w-96 lg:h-80 flex flex-col items-center gap-3 bg-no-repeat bg-right-top"
                style={{ backgroundImage: `url(${shareDiamondBg1})` }}
            >
                <span className="mt-3 text-xs lg:text-sm font-medium">
                    {title}
                </span>
                <div
                    className="border-cornFlower border-[1px] rounded-md p-2 
                                    w-48 h-44 mt-3"
                >
                    <div className="bg-shareQRBg rounded-md p-2 h-full">
                        <div className="bg-white text-black rounded-md p-1 h-full">
                            <QR code={link} width={138} height={80} />
                        </div>
                    </div>
                </div>
                <div className="w-full relative -top-7">
                    <div className="absolute -top-6 lg:top-4 -left-8">
                        <img
                            src={icons[1].icon}
                            alt=""
                            className="-rotate-45 w-20 lg:w-[100px]"
                        />
                    </div>
                    <div className="absolute -top-6 lg:top-2 left-4">
                        <img
                            src={icons[0].icon}
                            alt=""
                            className="w-24 lg:w-[118px] -rotate-12"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function socialSection(link: string): JSX.Element {
    return (
        <div className="flex flex-col justify-start gap-3">
            <hr />
            <SocialButton
                shareLink={link}
                title="Share with social friends"
                className="gap-3"
                textClass="text-sm font-medium text-darker"
            />
        </div>
    )
}

export function CopyDown(
    link: string,
    close: any,
    name: string,
    btnIcon: any,
    isShare: boolean = false,
    callBack: any = (e: any) => {}
): JSX.Element {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setMessage("")
            setError(false)
            setLoading(false)
        }, 10000)
    }, [message])

    return (
        <div className="flex flex-col gap-2">
            <span className="text-sm font-medium capitalize">
                Copy The Link:
            </span>
            <div className="bg-snow border border-grayscale rounded-lg p-2">
                <div className="flex gap-2 items-center">
                    {/* <span className="text-secondary text-sm font-medium"> */}
                    <input
                        type="text"
                        value={link}
                        disabled
                        className="w-full text-secondary text-sm font-medium"
                    />
                    {/* {link} */}
                    {/* </span> */}
                    <BtnClipboard
                        text={link}
                        title="Copy"
                        icon={""}
                        className="bg-white p-2 rounded border-[2px]
                             border-grayscale"
                        textClass="text-[12px] font-medium text-secondary"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <div className="flex gap-2 mt-3">
                    <MlmButton
                        text={"Close invite"}
                        className="text-cardText text-sm 
                                        font-normal border-[1px] border-cardText rounded-[4px] 
                                        font-poppins
                                        w-full"
                        callBack={() => {
                            close(false)
                        }}
                    />
                    <MlmButton
                        text={name}
                        loading={isShare ? loading : false}
                        className="text-white text-sm 
                                        font-normal border-[1px] bg-cardText rounded-[4px] 
                                        w-full font-poppins"
                        icon={btnIcon ? btnIcon : null}
                        callBack={async (e: any) => {
                            if (isShare && callBack) {
                                setLoading(true)
                                const res = await callBack(e)
                                setLoading(false)
                                if (!res) {
                                    setMessage(
                                        "there are no response from server"
                                    )
                                    setError(true)
                                    return
                                } else if (res.status) {
                                    setMessage(res.data)
                                    return
                                } else if (res.data) {
                                    setMessage(res.data.message)
                                } else {
                                    setMessage(res)
                                }
                                setError(true)
                            } else if (callBack) {
                                setLoading(true)
                                callBack(e)
                                setLoading(false)
                            }
                        }}
                    />
                </div>
                {message && (
                    <p
                        className={`text-xs font-semibold ${
                            error ? `text-red-600` : `text-green`
                        }`}
                    >
                        {message}
                    </p>
                )}
            </div>
        </div>
    )
}

const shareContants = {
    QRContent,
    shareContent,
    qrSection,
    socialSection,
    CopyDown,
    tagChip,
    emailSection,
}

export default shareContants
