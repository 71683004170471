export const en = "/img/other/en.svg"
export const loader = "/img/other/loader.svg"
export const loaderWhite = "/img/other/loader-white.svg"
export const shiningStarBlue = "/img/other/shiningStarBlue.svg"
export const shiningStarWhite = "/img/other/shiningStarWhite.svg"
export const sampleModelProfileImage = "/img/other/model.png"
export const line = "/img/other/line.svg"
export const moon = "/img/other/moon.svg"
export const pdfview = "/img/other/pdf.png"
export const youtube = "/img/other/youtube.png"
export const KTM_logo = "/img/partnerships/keyToMarkets.png"
export const SoftBuilders_logo = "/img/partnerships/SoftBuilders.png"
export const TOPKYC_logo = "/img/partnerships/topkyc.png"
export const LinkIcon = "/img/partnerships/LinkIcon.svg"
export const logo = "/img/logo/logo.png"
export const KYC_icon = "/img/logo/kycIcon.svg"
export const KYC_logo = "/img/logo/kycLogo.svg"
export const ppt = "/img/other/ppt.png"
export const error404 = "/img/other/error404.svg"
export const wallpaper = "/img/other/wallpaper.svg"
export const success = "/img/other/success.svg"
export const sumsub = "/img/other/sumsub.svg"
export const facebook = "/img/other/facebook.svg"
export const linkedin = "/img/other/linkedin.svg"
export const tumblr = "/img/other/tumblr.svg"
export const avatar = "/img/other/avatar.svg"
export const tick = "/img/other/tick.svg"
export const copy = "/img/other/copy.svg"
export const pp = "/img/other/pp.svg"
export const message = "/img/other/message.svg"
export const userIcon = "/img/other/user.svg"
export const calendar = "/img/other/calendar.svg"
export const share = "/img/other/share.svg"
export const qrcode = "/img/other/qrcode.svg"
export const rankstatus = "/img/other/rank-status.svg"
export const whatsapp = "/img/other/whatsapp.svg"
export const fb = "/img/other/fb.svg"
export const messenger = "/img/other/messenger.svg"
export const telegram = "/img/other/telegram.svg"
export const twitter = "/img/other/twitter.svg"
export const dottedLines = "/img/other/dotted-lines.svg"
export const ellipse = "/img/other/ellipse.svg"
export const online = "/img/other/online.svg"
export const approved = "/img/other/approved.svg"
export const enterence = "/img/other/enterence.svg"
export const forward = "/img/other/forward.svg"
export const starLevel = "/img/other/star-level.svg"
export const rankLevel = "/img/other/rankLevel.svg"
export const triangle = "/img/other/triangle.svg"
export const deposit = "/img/other/deposit.svg"
export const euroGreen = "/img/other/euro-green.svg"
export const euroPurple = "/img/other/euro-purple.svg"
export const euroYellow = "/img/other/euro-yellow.svg"
export const euroBlank = "/img/other/euro-blank.svg"
export const courselArrowBtn = "/img/other/coursel-arrow-btn.svg"
export const arrowBtn = "/img/other/arrow-btn.svg"
export const news = "/img/other/news.svg"
export const exportsvg = "/img/other/exportsvg.svg"
export const importsvg = "/img/other/importsvg.svg"
export const importIcon = "/img/other/import-icon.svg"
export const upload = "/img/other/upload.svg"
export const graphSm = "/img/other/graph-Sm.svg"
export const graphSmRed = "/img/other/graph-Sm-red.svg"
export const diamondTransparent = "/img/other/diamond-Transparent.svg"
export const downArrow = "/img/other/down-arrow.svg"
export const upArrow = "/img/other/up-arrow.svg"
export const rightArrow = "/img/other/right-arrow.svg"
export const downArrowStroke = "/img/other/down-arrow-stroke.svg"
export const leftArrowTPGN = "/img/other/left-table-pagination-arrow.svg"
export const rightArrowTPGN = "/img/other/right-table-pagination-arrow.svg"
export const downArrowTF = "/img/other/down-arrow-table-filter.svg"
export const downArrowTFLight = "/img/other/down-arrow-table-filter-light.svg"
export const england = "/img/other/england.svg"
export const italy = "/img/other/italy.svg"
export const spain = "/img/other/spain.svg"

export const moonBlue = "/img/other/moon-blue.svg"
export const euroWhite = "/img/other/euro-white.svg"
export const euroBlue = "/img/other/euro-blue.svg"
export const dollarWhite = "/img/other/dollar-white.svg"
export const dollarBlue = "/img/other/dollar-blue.svg"
export const bell = "/img/other/bell.svg"
export const search = "/img/other/search.svg"
export const adda = "/img/other/adda.svg"
export const menu = "/img/other/menu.svg"
export const cryptoBTC = "/img/other/crypto-btc.svg"
export const cryptoUSDT = "/img/other/crypto-usdt.svg"
export const cryptoETH = "/img/other/crypto-eth.svg"
export const cryptoTransparentETH = "/img/other/crypto-eth-transparent.svg"
export const infoIcon = "/img/other/info-icon.svg"
export const deletet = "/img/other/DeleteT.svg"
export const infoGrayIcon = "/img/other/info-gray-icon.svg"
export const see = "/img/other/see.svg"
export const icon1 = "/img/other/icon1.svg"
export const icon2 = "/img/other/icon2.svg"
export const icon3 = "/img/other/icon3.svg"
export const icon4 = "/img/other/icon4.svg"
export const icon11 = "/img/other/icon11.svg"
export const icon111 = "/img/other/icon11.png"
export const icon22 = "/img/other/icon22.svg"
export const icon222 = "/img/other/icon22.png"
export const successs = "/img/other/sucess.svg"
export const failure = "/img/other/cross.svg"
export const icon33 = "/img/other/icon33.svg"
export const icon44 = "/img/other/icon44.svg"
export const edit = "/img/other/Edit.svg"
export const eth = "/img/other/ETH.svg"
export const ethSVG = "/img/other/ETH.svg"

export const Delete = "/img/other/Delete.svg"
export const btc = "/img/other/BTC.svg"

export const bank = "/img/other/BANK.svg"
export const checke = "/img/other/CHECKe.svg"
export const background = "/img/other/background.png"
export const icon = "/img/other/icon.png"
export const vector = "/img/other/Vector.png"
export const usdt = "/img/other/USDT.svg"
export const ellipse878 = "/img/other/Ellipse 878.png"
export const ellipse873 = "/img/other/Ellipse874.png"
export const ellipse874 = "/img/other/Ellipse874.png"
export const divider = "/img/other/divider.svg"
export const bagBlue = "/img/other/bagBlue.svg"
export const bagYellow = "/img/other/bagYellow.svg"
export const eye = "/img/other/eye.svg"
export const bgThemeLines = "/img/other/bgThemeLines.png"
export const bgThemeLinesGray = "/img/other/bgThemeLinesGray.png"
export const bgYellowDiamonds = "/img/other/bgYellowDiamonds.png"
export const bgThemeLinesRectangle = "/img/other/bgThemeLinesRectangle.png"
export const bgYellowDiamondsRectangle =
    "/img/other/bgYellowDiamondsRectangle.png"
export const darkBlue = "/img/other/dark-blue.svg"
export const diamondGray = "/img/other/diamond-gray.svg"
export const diamondGreen = "/img/other/diamond-green.svg"
export const bgDiamondRotate = "/img/other/bg-diamond-rotate.svg"
export const bgDiamondRotateRight = "/img/other/bg-diamond-rotate-right.svg"
export const bgDiamondRotateRightGreen =
    "/img/other/bg-diamond-rotate-rightGreen.svg"
export const checkMark = "/img/other/check-mark.svg"
export const crossMark = "/img/other/cross-mark.svg"
export const crossMarkCircle = "/img/other/cross-mark-circle.svg"
export const rankDiamond1 = "/img/other/rank-diamond-1.svg"
export const rankDiamond2 = "/img/other/rank-diamond-2.svg"
export const rankDiamond3 = "/img/other/rank-diamond-3.svg"
export const rankDiamond4 = "/img/other/rank-diamond-4.svg"
export const rankStatusDiamond1 = "/img/other/rank-status-diamond-1.svg"
export const rankStatusDiamond2 = "/img/other/rank-status-diamond-2.svg"
export const rankStatusDiamond3 = "/img/other/rank-status-diamond-3.svg"
export const rankStatusDiamond4 = "/img/other/rank-status-diamond-4.svg"
export const rankStatusDiamond5 = "/img/other/rank-status-diamond-5.svg"
export const rankStatusDiamond6 = "/img/other/rank-status-diamond-6.svg"
export const rankStatusDiamond7 = "/img/other/rank-status-diamond-7.svg"
export const rankStatusCheck = "/img/other/rank-status-check.svg"
export const lock = "/img/other/lock.svg"
export const lockWhite = "/img/rank/Lock.svg"
export const newsam = "/img/other/newsam.svg"
export const closeGray = "/img/other/close-gray.svg"
export const closeWhite = "/img/other/close-white.svg"
export const greenTick = "/img/other/green-tick.svg"
export const horiontalLine = "/img/other/horizontal-line.svg"
export const verticalLine = "/img/other/vertical-line.svg"
export const upArrowGreen = "/img/other/up-arrow-green.svg"
export const downArrowRed = "/img/other/down-arrow-red.svg"
export const rankDepositDiamond = "/img/other/rank-deposit-diamond.svg"
export const rankDepositDiamond2 = "/img/other/rank-deposit-diamond-2.svg"
export const rankImport = "/img/other/rank-import.svg"
export const mainWalletCardVertical = "/img/other/main-wallet-card-vertical.svg"
export const licensePremiumBG = "/img/other/premium-license.png"
export const licenseStandardBG = "/img/other/standard-license.png"
export const whiteSmallGold = "/img/other/white-gold-small.svg"
export const commissionBG1 = "/img/other/commission-bg-1.svg"
export const commissionBG2 = "/img/other/commission-bg-2.svg"
export const commissionBG3 = "/img/other/commission-bg-3.svg"
export const commissionBG4 = "/img/other/commission-bg-4.png"
export const verifiedCheck = "/img/other/verifiedCheck.svg"
export const filterIcon = "/img/other/filter.svg"
export const downloadIcon = "/img/other/download-icon.svg"
export const personIcon = "/img/other/person-icon.svg"
export const plus = "/img/other/plus.svg"
export const plusDark = "/img/other/plusDark.svg"
export const threeLines = "/img/other/three-lines.svg"
export const euroBlueBg = "/img/other/euroBlueBg.svg"
export const arrowMore = "/img/other/arrow-more.svg"
export const ambassadorGreen = "/img/rank/ambassador-green.svg"
export const ambassadorDisabled = "/img/rank/ambassador-disabled.svg"
export const manager = "/img/rank/manager.svg"
export const president = "/img/rank/president.svg"
export const user_default = "/img/other/user.png"
export const errorIcon = "/img/other/error-icon.png"
export const successIcon = "/img/other/success-icon.png"

export const tractor_dashboard = "/img/other/tractor.svg"
export const tractor_animation = "/img/other/tractor.gif"
export const lambo_animation = "/img/other/lambo.gif"
export const hummer_animation = "/img/other/hummer.gif"
export const tractor_2_animation = "/img/other/tractor_2.gif"
export const lambo_vector = "/img/other/lambo.svg"
export const tractor_vector = "/img/other/tractor.svg"
