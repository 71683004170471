import React, { useState } from "react"
import { useShareActions } from "../../../store/shares/shareAction"
import { useSelector } from "react-redux"
import { selectUser } from "../../../store/user/userSlice"

const EmailSection = () => {
    const [emails, setEmails] = useState(Array())
    const [text, setText] = useState("")
    const [validation, setValidation] = useState("")
    const [loading, setLoading] = useState(false)
    const { getShare } = useShareActions()
    const user = useSelector(selectUser)

    // ... rest of the component code

    return {
        emails,
        setEmails,
        text,
        setText,
        validation,
        setValidation,
        loading,
        setLoading,
        getShare,
        user,
    }
}

export default EmailSection
