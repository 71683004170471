//REDUX-TOOLKIT
import { useDispatch } from "react-redux"
//UTILS
import { ApiEndpoints } from "../../utils/network/ApiEndpoints"
import ApiRequest from "../../utils/network/ApiRequest"

export const useShareActions = () => {
    const getShare = async (data: any) => {
        return await ApiRequest(ApiEndpoints.SHARE_EMAILS.isAuthRequired)
            .request({
                method: "POST",
                url: ApiEndpoints.SHARE_EMAILS.url,
                data,
            })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => error)
    }

    return {
        getShare,
    }
}
