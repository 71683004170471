import moment from "moment"
import { POSITION_ICONS } from "../images"
import html2canvas from "html2canvas"
import { usdt, eth, bank, btc, checke } from "../images/other"
import { PaymentType } from "../types/types"

export const COMPANY_NAME: string = "BLpro8"
export const WEBSITE = "https://platform.blpro8.club"
export const COMPANY_EMAIL: string = "support@blpro8.club"
export const INITIATED: string = "INITIATED"
export const DENIED: string = "DENIED"
export const PENDING: string = "PENDING"
export const APPROVED: string = "APPROVED"
export const CARD_PAYMENT = "CARD_PAYMENT"
export const GIFTCOUPON = "GIFTCOUPON"
export const BANKWIRE = "BANKWIRE"
export const EWALLET = "EWALLET"
export const CRYPTOPAY = "CRYPTOPAY"
export const PAYMENT_METHODS = {
    CARD_PAYMENT,
    GIFTCOUPON,
    BANKWIRE,
    EWALLET,
    CRYPTOPAY,
}

export const paymentTypes: PaymentType[] = [
    { name: "USDT", label: "USDT", image: usdt, position: 0 },
    { name: "BTC", label: "BTC", image: btc, position: 0 },
    { name: "ETH", label: "ETH", image: eth, position: 0 },
    {
        name: "bank",
        label: "BANK",
        image: bank,
        position: 1,
    },

    {
        name: "creditCard",
        label: "CreditCard",
        image: checke,
        className:
            "relative underline cursor-pointer text-base transition-colors duration-300 hover:bg-white",
        position: 4,
        disabled: true,
    },
]

export const POSITION_INDEX = {
    CUSTOMER: 0,
    CONSULTANT: 1,
    EXECUTIVE: 2,
    MANAGER: 3,
    AMBASSADOR: 4,
    FOUNDER: 5,
    PRESIDENT: 6,
}

export const REFFER_URL: string = WEBSITE + "/auth/signup?ref="

export const RANK_LIST = [
    {
        name: "Customer",
        icon: POSITION_ICONS.CUSTOMER,
        active: true,
    },
    {
        name: "Consultant",
        icon: POSITION_ICONS.CONSULTANT,
        active: true,
    },
    {
        name: "Executive",
        icon: POSITION_ICONS.EXECUTIVE,
        active: true,
    },
    {
        name: "Manager",
        icon: POSITION_ICONS.MANAGER,
        active: true,
    },
    {
        name: "Ambassador",
        icon: POSITION_ICONS.AMBASSADOR,
        active: true,
    },
    {
        name: "Founder",
        icon: POSITION_ICONS.FOUNDER,
        active: true,
    },
    {
        name: "President",
        icon: POSITION_ICONS.PRESIDENT,
        active: true,
    },
]

export const bookingTypeOptions = [
    { name: "Free Event", value: "FREE_EVENT" },
    { name: "Paid", value: "PAID_EVENT" },
]

export const reservationTypeOptions = [
    { name: "Public", id: "PUBLIC" },
    { name: "Private", id: "PRIVATE" },
    { name: "Selected", id: "SELECTED" },
]

export const meetingUrlOptions = [
    "https://zoom.us/j/5551112222",
    "https://zoom.us/j/5551112222",
    "https://zoom.us/j/5551112222",
]

export const sourceOptions = [
    { name: "Zoom", value: "ZOOM" },
    { name: "Google Meet", value: "GOOGLE_MEET" },
    { name: "Microsoft Teams", value: "MICROSOFT_TEAMS" },
    { name: "Other", value: "OTHER" },
]

export const BROKER_URL =
    "https://secure.keytomarkets.com/links/go/4491?utm_content=user_id="
export const FX_BOOK =
    "https://www.myfxbook.com/members/BillonariosPro/billonarios-master-account-tractor-strategy/10196642"

export const momentFormat = (date) => {
    return moment(new Date(date), "YYYYMMDD").fromNow()
}

export const createCapture = ({ id, name = "Qr-Code", padding = "" }) => {
    const elementToCapture = document.getElementById(id) // Replace 'element-id' with the actual ID of the element you want to capture
    if (!elementToCapture) return
    let originalPadding = ""
    if (padding) {
        originalPadding = elementToCapture.style.padding
        elementToCapture.style.padding = padding
    }

    html2canvas(elementToCapture).then((canvas) => {
        // `canvas` contains the screenshot as a canvas element
        // Now you can proceed to download the image
        // downloadScreenshot(canvas)
        const link = document.createElement("a")
        link.download = name + ".png"
        link.href = canvas.toDataURL("image/png") // Convert canvas to data URL
        link.click()
        if (padding) elementToCapture.style.padding = originalPadding
    })
}
