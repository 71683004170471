//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    rankList: any
    rankOverview: any
    rules: any
    commissionBonuses: any
    commissionBonusesCharts: any
    rankPercentages: any
    ibRules: any
    ibVolume: any
    ibPercentages: any
    ibBonuses: any
    referralBonusHistory: any
    iBBonusHistory: any
}

const initialState: State = {
    rankList: null,
    rankOverview: null,
    rules: null,
    commissionBonuses: null,
    commissionBonusesCharts: null,
    rankPercentages: null,
    ibRules: null,
    ibVolume: null,
    ibPercentages: null,
    ibBonuses: null,
    referralBonusHistory: {
        objects: [],
        list_pages: [],
        total_objects: 0,
        total_pages: 0,
        has_next: true,
        page: 1,
        limit: 10,
    },
    iBBonusHistory: {
        objects: [],
        list_pages: [],
        total_objects: 0,
        total_pages: 0,
        has_next: true,
        page: 1,
        limit: 10,
    }
}

const slice = createSlice({
    name: "rank",
    initialState,
    reducers: {
        setRankList: (state, action: PayloadAction<any>) => {
            return { ...state, rankList: action.payload }
        },
        setRankOverview: (state, action: PayloadAction<any>) => {
            return { ...state, rankOverview: action.payload }
        },
        setRules: (state, action: PayloadAction<any>) => {
            return { ...state, rules: action.payload }
        },
        setIBRules: (state, action: PayloadAction<any>) => {
            return { ...state, ibRules: action.payload }
        },
        setIBVolume: (state, action: PayloadAction<any>) => {
            return { ...state, ibVolume: action.payload }
        },
        setIBPercentages: (state, action: PayloadAction<any>) => {
            return { ...state, ibPercentages: action.payload }
        },
        setCommissionBonuses: (state, action: PayloadAction<any>) => {
            return { ...state, commissionBonuses: action.payload }
        },
        setCommissionBonusesCharts: (state, action: PayloadAction<any>) => {
            return { ...state, commissionBonusesCharts: action.payload }
        },
        setRankPercentages: (state, action: PayloadAction<any>) => {
            return { ...state, rankPercentages: action.payload }
        },
        setIbBonuses: (state, action: PayloadAction<any>) => {
            return { ...state, ibBonuses: action.payload }
        },
        setReferralBonusHistory: (state, action: PayloadAction<any>) => {
            return { ...state, referralBonusHistory: action.payload }
        },
        setIBBonusHistory: (state, action: PayloadAction<any>) => {
            return { ...state, iBBonusHistory: action.payload }
        },
    },
})

export const {
    setRankList,
    setRankOverview,
    setRules,
    setCommissionBonuses,
    setRankPercentages,
    setCommissionBonusesCharts,
    setIBRules,
    setIBVolume,
    setIBPercentages,
    setIbBonuses,
    setReferralBonusHistory,
    setIBBonusHistory
} = slice.actions

export const selectRank = (state: { rank: State }) => state.rank

export default slice.reducer
