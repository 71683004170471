export const Home = "/img/drawer/light/home.svg"
export const ArrowDown = "/img/drawer/light/Arrow - Down Square.svg"
export const ArrowRight = "/img/drawer/light/Arrow - Right Square.svg"
export const ArrowUp = "/img/drawer/light/Arrow - Up Square.svg"
export const bank = "/img/drawer/light/bank.svg"
export const card = "/img/drawer/light/card.svg"
export const Category = "/img/drawer/light/Category.svg"
export const Chart = "/img/drawer/light/Chart.svg"
export const Commission = "/img/drawer/light/Commission.svg"
export const DangerTriangle = "/img/drawer/light/Danger Triangle.svg"
export const Document = "/img/drawer/light/Document.svg"
export const emptyWallet = "/img/drawer/light/empty-wallet.svg"
export const flash = "/img/drawer/light/flash.svg"
export const Play = "/img/drawer/light/Play.svg"
export const HandShake = "/img/drawer/light/handShake.svg"
export const Profile = "/img/drawer/light/Profile.svg"
export const QuestionCircleOutlined =
    "/img/drawer/light/question-circle-outlined.svg"
export const SecuritySafe = "/img/drawer/light/security-safe.svg"
export const Setting = "/img/drawer/light/Setting.svg"
export const Settings = "/img/drawer/light/Settings.svg"
export const Star = "/img/drawer/light/Star.svg"
export const lock = "/img/drawer/light/lock.svg"
export const Search = "/img/drawer/light/search.svg"
export const Bell = "/img/drawer/light/bell.svg"
export const Menu = "/img/drawer/light/Menu.svg"
export const Bag = "/img/drawer/light/Bag.svg"
export const Bookmark = "/img/drawer/light/Bookmark.svg"
export const Buy = "/img/drawer/light/Buy.svg"
export const Download = "/img/drawer/light/Download.svg"
export const Strategies = "/img/drawer/light/Strategies.svg"
export const event = "/img/drawer/light/event.svg"
export const calendar = "/img/drawer/light/calendar.svg"
export const Nft = "/img/drawer/light/nft.svg"
export const HistoryLight = "/img/drawer/light/historyLight.svg"
