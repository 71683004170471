import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress"
import { styled } from "@mui/material/styles"

function item({ name, value, className = "" }): JSX.Element {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "white",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "#1D417C",
        },
    }))

    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            <div className="flex justify-between text-sm font-medium text-secondary">
                <span>{name}</span>
                <span>{value}%</span>
            </div>
            <BorderLinearProgress variant="determinate" value={value} />
        </div>
    )
}

function array({ data, className = "", itemClass = "" }): JSX.Element {
    return (
        <div className={`flex flex-col gap-2 ${className}`}>
            {data.map((country: any, index: number) => (
                <div key={index}>
                    {item({
                        name: country.name,
                        value: country.value,
                        className: itemClass,
                    })}
                </div>
            ))}
        </div>
    )
}

const LineProgress = {
    item,
    array,
}

export default LineProgress
