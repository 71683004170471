//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Strategy } from "../../types/types"
import { Fund } from "../../interfaces/interfaces"

// COMMISSION_WALLET
// PURCHASE_WALLET
interface State {
    wallet: any
    transactionHistory: any
    internalTransfer: any
    externalTransfer: any
    chartData: any
    deposit: any
    internalExternalHistory: any
    addressList: any[]
    withDraw: any
    transactionFee: any
    max_min_amount: any
    banksDetails: any[]
}

const initialState: State = {
    wallet: null,
    transactionHistory: null,
    internalTransfer: null,
    externalTransfer: null,
    chartData: null,
    deposit: null,
    internalExternalHistory: null,
    addressList: [],
    withDraw: null,
    transactionFee: null,
    max_min_amount: null,
    banksDetails: [],
}

const slice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setWallet: (state, action: PayloadAction<any>) => {
            return { ...state, wallet: action.payload }
        },
        setTransactionHistory: (state, action: PayloadAction<any>) => {
            return { ...state, transactionHistory: action.payload }
        },
        setInternalTransfer: (state, action: PayloadAction<any>) => {
            return { ...state, internalTransfer: action.payload }
        },
        setExternalTransfer: (state, action: PayloadAction<any>) => {
            return { ...state, externalTransfer: action.payload }
        },
        setChartData: (state, action: PayloadAction<any>) => {
            return { ...state, chartData: action.payload }
        },
        setDeposit: (state, action: PayloadAction<any>) => {
            return { ...state, deposit: action.payload }
        },
        setInternalExternalHistory: (state, action: PayloadAction<any>) => {
            return { ...state, internalExternalHistory: action.payload }
        },
        setAddressList: (state, action: PayloadAction<any>) => {
            return { ...state, addressList: action.payload }
        },
        setWithDraw: (state, action: PayloadAction<any>) => {
            return { ...state, withDraw: action.payload }
        },
        setTransactionFee: (state, action: PayloadAction<any>) => {
            return { ...state, transactionFee: action.payload }
        },
        setMaxMinAmount: (state, action: PayloadAction<any>) => {
            return { ...state, max_min_amount: action.payload }
        },
        setBanksDetails: (state, action: PayloadAction<any>) => {
            return { ...state, banksDetails: action.payload }
        },
    },
})

export const {
    setWallet,
    setTransactionHistory,
    setInternalTransfer,
    setExternalTransfer,
    setChartData,
    setDeposit,
    setInternalExternalHistory,
    setAddressList,
    setWithDraw,
    setTransactionFee,
    setMaxMinAmount,
    setBanksDetails,
} = slice.actions
export const selectWallet = (state: { wallet: State }) => state.wallet
export default slice.reducer
