//REDUX
import { combineReducers } from "redux"
//REDUX-TOOLKIT
import { configureStore } from "@reduxjs/toolkit"
//SLICES
import drawerSlice from "./drawer/drawerSlice"
import { setDrawerTab } from "./drawer/drawerSlice"
import userSlice from "./user/userSlice"
import productsSlice from "./products/productsSlice"
import settingSlice from "./setting/settingSlice"
import strategySlice from "./strategy/strategySlice"
import communitySlice from "./community/communitySlice"
import eventsSlice from "./events/eventsSlice"
import walletsSlice from "./wallets/walletsSlice"
import storeSlice from "./store/storeSlice"
import rankSlice from "./rank/rankSlice"
import tutorialSlice from "./tutorials/tutorialSlice"
import referralSlice from "./referral/referralSlice"
import supportSlice from "./support/supportSlice"
import notificationsSlice from "./notifications/notificationsSlice"
import contractSlice from "./contract/contractSlice"
import newsSlice from "./news/newsSlice"
import userlistSlice from "./userlist/userlistSlice"

const rootReducer = combineReducers({
    drawer: drawerSlice,
    user: userSlice,
    products: productsSlice,
    setting: settingSlice,
    strategy: strategySlice,
    community: communitySlice,
    rank: rankSlice,
    events: eventsSlice,
    wallet: walletsSlice,
    store: storeSlice,
    tutorial: tutorialSlice,
    support: supportSlice,
    referral: referralSlice,
    notifications: notificationsSlice,
    contract: contractSlice,
    news: newsSlice,
    userlist: userlistSlice,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const handleTabChange = (newTab: string) =>
    store.dispatch(setDrawerTab(newTab))
