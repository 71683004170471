//AXIOS
import axios from "axios"
//API
import { API_INSTANCE } from "./ApiEndpoints"
//UTILS
import currentStorage from "../../utils/currentStorage"

const storage = currentStorage()

const logout = () => {
    storage.removeItem("accessToken")
    storage.removeItem("refreshToken")
    window.location.href = "/auth/login"
}

export default function ApiRequest(isAuth: boolean, baseURL = API_INSTANCE) {
    const request = axios.create({
        baseURL: baseURL,
        headers: isAuth
            ? {
                  Authorization: `Bearer ${
                      storage.getItem("accessToken")
                          ? storage.getItem("accessToken")
                          : null
                  }`,
              }
            : {},
        responseType: "json",
        socketPath: null,
    })

    request.interceptors.response.use(
        (response: any): any => response,
        (error: any) => {
            if (error.code === "ERR_NETWORK") {
                return
            }

            if (error.response.status === 401) {
                logout()
            }

            if (error.response.status === 403) {
                logout()
                return
            }

            return Promise.reject(error.response)
        }
    )

    return request
}
