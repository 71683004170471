import React from "react"

/* App Pages */
export const Home = React.lazy(() => import("./Home"))

// wallets
export const DepositPage = React.lazy(() => import("./Wallets/DepositPage"))
export const DepositDetailsPage = React.lazy(
    () => import("./Wallets/DepositDetailsPage")
)
export const DepositCryptoDetailsPage = React.lazy(
    () => import("./Wallets/DepositCryptoDetailsPage")
)
export const WalletsOverview = React.lazy(
    () => import("./Wallets/WalletsOverview")
)
export const WalletsWithdraw = React.lazy(
    () => import("./Wallets/WalletsWithdraw")
)
export const CommunityPage = React.lazy(
    () => import("./Community/CommunityPage")
)
export const CommunityPageNew = React.lazy(
    () => import("./Community/CommunityPageNew")
)
export const WalletsTransfer = React.lazy(
    () => import("./Wallets/WalletsTransfer")
)
export const WalletsInternalExternalTransfer = React.lazy(
    () => import("./Wallets/InternalExternalTransfer")
)
export const TransactionHistory = React.lazy(
    () => import("./Wallets/TransactionHistory")
)
export const AddressBook = React.lazy(() => import("../components/AddressBook"))
export const AddAddressPopup = React.lazy(
    () => import("../components/AddAddressPopup")
)

// store
export const Overview_ = React.lazy(() => import("./Store/Overview"))
export const Products = React.lazy(() => import("./Store/Products"))
export const Strategies = React.lazy(() => import("./Store/Strategies"))
export const Coupons = React.lazy(() => import("./Store/Coupons"))
export const GenerateCoupons = React.lazy(
    () => import("./Store/Coupons/GenerateCoupons")
)
export const PurchaseHistory = React.lazy(
    () => import("./Store/PurchaseHistory")
)

// rank
export const Overview = React.lazy(() => import("./Rank/Overview"))
export const Commissions = React.lazy(() => import("./Rank/Commissions"))
export const Bonuses = React.lazy(() => import("./Rank/BonusHistory"))
export const Rules = React.lazy(() => import("./Rank/Rules"))
export const ReferralLink = React.lazy(() => import("./ReferralLink"))

// events
export const EventListPage = React.lazy(() => import("./Events/EventListPage"))
export const EventAddPage = React.lazy(() => import("./Events/EventAddPage"))
export const GuestListPage = React.lazy(() => import("./Events/GuestListPage"))
export const GuestAddPage = React.lazy(() => import("./Events/GuestAddPage"))

// other
export const Contract = React.lazy(() => import("./Contract"))
export const TermsAndCondition = React.lazy(() => import("./TermsAndCondition"))
export const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"))
export const Faq = React.lazy(() => import("./Faq"))
export const Tutorials = React.lazy(() => import("./Tutorials"))
export const Partnerships = React.lazy(() => import("./Partnerships"))
export const keyToMarkets = React.lazy(() => import("./KeyToMarkets"))
export const Support = React.lazy(() => import("./Support"))
export const NewTicket = React.lazy(() => import("./Support/NewTicket"))
export const Conversation = React.lazy(() => import("./Support/Conversation"))
export const Reply = React.lazy(() => import("./Support/NewReply"))
export const Notifications = React.lazy(() => import("./Notifications"))
export const Settings = React.lazy(() => import("./Settings"))
export const Logout = React.lazy(() => import("./Logout"))
export const NFT = React.lazy(() => import("./NFT"))

export const News = React.lazy(() => import("./News"))
export const Activities = React.lazy(() => import("./Activities"))
export const Impersonate = React.lazy(() => import("./Impersonate"))

/* Auth Pages */
// login
export const Login = React.lazy(() => import("./Auth/Login"))
export const AcceptTnC = React.lazy(() => import("./Auth/Login/AcceptTnC"))
export const StartYourKYC = React.lazy(
    () => import("./Auth/Login/StartYourKYC")
)
export const UploadYourDocument = React.lazy(
    () => import("./Auth/Login/UploadYourDocument")
)
export const PendingKYCProcedure = React.lazy(
    () => import("./Auth/Login/PendingKYCProcedure")
)
export const PendingRegistration = React.lazy(
    () => import("./Auth/Login/PendingRegistration")
)
export const InsertResidentRetail = React.lazy(
    () => import("./Auth/Login/InsertResidentRetail")
)
export const SignatureDraw = React.lazy(
    () => import("./Auth/Login/SignatureDraw")
)
export const KYCRejected = React.lazy(() => import("./Auth/Login/KYCRejected"))
// signup
export const Signup = React.lazy(() => import("./Auth/Signup"))
export const Questions = React.lazy(() => import("./Auth/Signup/Questions"))
export const VerifyEmail = React.lazy(() => import("./Auth/Signup/VerifyEmail"))
export const SuccessVerifyEmail = React.lazy(
    () => import("./Auth/Signup/SuccessVerifyEmail")
)
export const QuestionSent = React.lazy(
    () => import("./Auth/Signup/QuestionSent")
)
export const PendingApproval = React.lazy(
    () => import("./Auth/Signup/PendingApproval")
)
export const VerifyingEmail = React.lazy(
    () => import("./Auth/Signup/VerifyingEmail")
)
export const UpdateYourProfile = React.lazy(
    () => import("./Auth/Login/UpdateYourProfile")
)
// forgotpassword
export const ForgotPassword = React.lazy(() => import("./Auth/ForgotPassword"))
export const CreateNewPassword = React.lazy(
    () => import("./Auth/ForgotPassword/CreateNewPassword")
)
export const TranscationStatus = React.lazy(() => import("./TranscationStatus"))

/* 404 Page */
export const Error = React.lazy(() => import("./Error"))
