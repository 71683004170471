//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    supportList: any
    ticketsCategoryList: any
    conversationList: any
    conversationReply: any
}

const initialState: State = {
    supportList: null,
    ticketsCategoryList: [],
    conversationList: null,
    conversationReply: null,
}

const slice = createSlice({
    name: "support",
    initialState,
    reducers: {
        setSupportList: (state, action: PayloadAction<any>) => {
            return { ...state, supportList: action.payload }
        },
        setTicketsCategoryList: (state, action: PayloadAction<any>) => {
            return { ...state, ticketsCategoryList: action.payload }
        },
        setConversationList: (state, action: PayloadAction<any>) => {
            return { ...state, conversationList: action.payload }
        },
        setConversationReply: (state, action: PayloadAction<any>) => {
            return { ...state, conversationReply: action.payload }
        },
    },
})

export const {
    setSupportList,
    setTicketsCategoryList,
    setConversationList,
    setConversationReply,
} = slice.actions

export const selectSupport = (state: { support: State }) => state.support

export default slice.reducer
