//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface Product {
    id: number
    name: string
    code: string
    active: boolean
    price: number
    license_expiration: string
    license_activation: string
    license_completed: string
    auto_renew_active: boolean
}

interface ProductSliceState {
    products: Product[] | null
}

const initialState: ProductSliceState = {
    products: null,
}

const productsSlice = createSlice({
    name: "productSlice",
    initialState,
    reducers: {
        setProducts: (state, action: PayloadAction<Product[]>) => {
            return { ...state, products: action.payload }
        },
    },
})

export const { setProducts } = productsSlice.actions
export const selectProducts = (state: { products: ProductSliceState }) =>
    state.products.products
export default productsSlice.reducer
