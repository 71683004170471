//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    bookingTypeOptions,
    reservationTypeOptions,
    sourceOptions,
} from "../../utils/allConstants"
import moment from "moment"

export interface Brack {
    start_time: any
    end_time: any
}

interface State {
    eventType: any

    bookingType: any
    bookingTypeValue: any

    reservationType: any
    reservationTypeId: any

    guestsList: any
    guestsListId: any

    title: any
    titleId: any

    category: any
    categoryId: any

    languages: []
    languageCodes: []
    imageUrl: any

    team: any
    teamId: any

    organizer: any
    organizerId: any

    meetingUrl: any

    source: any
    sourceValue: any

    credentials: any
    meetingID: any
    passcode: any

    venue: any
    venueId: any

    startDate: any
    endDate: any
    timezone: any
    totalBreaks: number
    breakTimes: any
    shortDescription: any
    longDescription: any
    status: any
    scheduleDate: any
    eventsCalendar: any
}

export const initialState: State = {
    eventType: "",

    bookingType: "",
    bookingTypeValue: "",

    reservationType: "",
    reservationTypeId: "",

    guestsList: "",
    guestsListId: "",

    title: "",
    titleId: "",

    category: "",
    categoryId: "",

    languages: [],
    languageCodes: [],
    imageUrl: "",

    team: "",
    teamId: "",

    organizer: "",
    organizerId: "",

    meetingUrl: "",

    source: "",
    sourceValue: "",

    credentials: false,
    meetingID: "",
    passcode: "",

    venue: "",
    venueId: "",

    startDate: null,
    endDate: null,
    timezone: "",
    totalBreaks: 0,
    breakTimes: [],
    shortDescription: "",
    longDescription: "",
    status: "",
    scheduleDate: null,
    eventsCalendar: null,
}

const slice = createSlice({
    name: "events",
    initialState,
    reducers: {
        resetEventData: () => {
            return { ...initialState }
        },
        setEventData: (state, action: PayloadAction<any>) => {
            return { ...state, [action.payload.key]: action.payload.value }
        },
        setEventsCalendar: (state, action: PayloadAction<any>) => {
            return { ...state, eventsCalendar: action.payload }
        },
        setBreak: (state, action: PayloadAction<any>) => {
            const breaks: Brack[] = []
            for (let index = 0; index < action.payload; index++) {
                breaks.push({
                    start_time: moment(),
                    end_time: moment(),
                })
            }

            return {
                ...state,
                totalBreaks: action.payload,
                breakTimes: breaks,
            }
        },
        setEventItem: (state, action: PayloadAction<any>) => {
            const event: any = action.payload

            try {
                const newObj: any = {
                    eventType: event?.type,
                    eventTypeValue: event?.type,

                    bookingType: bookingTypeOptions?.find(
                        (item) => item.value === event?.booking_type
                    )?.name,
                    bookingTypeValue: event?.booking_type,

                    reservationType: reservationTypeOptions?.find(
                        (item) => item.id === event?.reservation_type
                    )?.id,
                    reservationTypeId: event?.reservation_type,

                    guestsList: event?.guests_list?.name ?? "",
                    guestsListId: event?.guests_list?.id ?? "",

                    title: event?.title?.name,
                    titleId: event?.title?.id,

                    category: event?.category?.name,
                    categoryId: event?.category?.id,

                    languages: event?.languages?.map((item) => item?.name),
                    languageCodes: event?.languages?.map((item) => item?.code),
                    imageUrl: event?.image,

                    team: { name: event?.team?.name, id: event?.team?.id },
                    teamId: event?.team?.id,

                    organizer: {
                        name: event?.organizer?.name,
                        id: event?.organizer?.id,
                    },
                    organizerId: event?.organizer?.id,

                    meetingUrl: event?.meeting_url,

                    source: sourceOptions?.find(
                        (item) => item.value === event?.meeting_source
                    )?.name,
                    sourceValue: event?.meeting_source,

                    credentials: event?.credentials_required,
                    meetingID: event?.meeting_id ?? "",
                    passcode: event?.meeting_passcode ?? "",

                    venue: event?.venue ?? "",
                    venueId: event?.venue?.id ?? "",

                    startDate: event?.start_date,
                    endDate: event?.end_date,
                    timezone: event?.time_zone ?? "",
                    totalBreaks: event?.breaks?.length,
                    breakTimes: event?.breaks,
                    shortDescription: event?.short_description,
                    longDescription: event?.description,
                    status: event?.status,
                    scheduleDate: event?.scheduled_time ?? "",
                }

                return {
                    ...state,
                    ...newObj,
                }
            } catch (err) {
                console.log(err)
            }
        },
    },
})

export const {
    setEventData,
    setBreak,
    setEventItem,
    setEventsCalendar,
    resetEventData,
} = slice.actions
export const selectEventData = (state: { events: State }) => state.events
export default slice.reducer
