//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    list: any[]
    memberDetails: any
    memberExpendList: any[]
    communityOverview: any
    expand: boolean
}

const initialState: State = {
    list: [],
    memberDetails: null,
    memberExpendList: [],
    communityOverview: null,
    expand: false,
}

const slice = createSlice({
    name: "community",
    initialState,
    reducers: {
        setCommunity: (state, action: PayloadAction<any>) => {
            return { ...state, list: action.payload }
        },
        setCommunityMemberDetails: (state, action: PayloadAction<any>) => {
            return { ...state, memberDetails: action.payload }
        },
        setDefaultMember: (state, action: PayloadAction<any>) => {
            return { ...state, list: action.payload }
        },
        setCommunityOverview: (state, action: PayloadAction<any>) => {
            return { ...state, communityOverview: action.payload }
        },
        setCommunityExpand: (state, action: PayloadAction<any>) => {
            return { ...state, expand: action.payload }
        },
    },
})

export const {
    setCommunity,
    setCommunityMemberDetails,
    setDefaultMember,
    setCommunityOverview,
    setCommunityExpand,
} = slice.actions

export const selectCommunity = (state: { community: State }) => state.community

export default slice.reducer
