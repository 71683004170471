import { RANK_LIST } from "./allConstants"

export const formatNumberWithComma = (num: number) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

// numberFormat
// european style number format with comma and dot (ex: 1.000,00)
export const numberFormat = (num: number = 0, isHide = false) => {
    const value = num
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    if (isHide) return hideNumber(value)
    return value
}

export const hideNumber = (value: string) => {
    var total = value.replace(/\d/g, "*")
    return total
}

export const generateRandomString = (length: number) => {
    let result = ""
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
        counter += 1
    }
    return result
}

export const getRankCurrentWithNextIcons = (rank: string) => {
    const array: any = []
    if (!rank) return array

    try {
        for (var i = 0; i < RANK_LIST.length; i++) {
            if (RANK_LIST[i]?.name?.toUpperCase() === rank?.toUpperCase()) {
                RANK_LIST[i].active = true
                array.push(RANK_LIST[i])
            } else if (array.length === 1) {
                RANK_LIST[i].active = false
                array.push(RANK_LIST[i])
                break
            }
        }
    } catch (error) {
        return array
    }
    return array
}

export function extractNumbers(inputArray) {
    const resultArray = inputArray.map((item) => {
        const match = item.match(/€(\d+)/)
        return match ? Number(match[1]) : null
    })
    return resultArray
    /* if array ["LlvUDVbNzB2 - €23", "HC0qdQNmZKh - €23"] then result ["23", "23"] */
}

export function extractAlphanumeric(inputArray) {
    const resultArray = inputArray.map((item) => {
        const match = item.match(/([A-Za-z0-9]+) -/)
        return match ? match[1] : null
    })
    return resultArray
    /* if array ["LlvUDVbNzB2 - €23", "HC0qdQNmZKh - €23"] then result ["LlvUDVbNzB2", "HC0qdQNmZKh"] */
}

export function formatDateToYYYYMMDD(dateObj) {
    if (
        dateObj &&
        dateObj.$y !== undefined &&
        dateObj.$M !== undefined &&
        dateObj.$D !== undefined
    ) {
        const year = dateObj.$y
        const month = String(dateObj.$M + 1).padStart(2, "0") // Month is zero-based
        const day = String(dateObj.$D).padStart(2, "0")

        return `${year}-${month}-${day}`
    } else {
        console.error("Invalid date object:", dateObj)
        // Handle the invalid date object as needed
        return null // or throw an error
    }
}

export const openNewTab = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};