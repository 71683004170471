import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material"
import React from "react"

interface Props {}

const QuestionAns = ({ faq, expanded, handleChange, index }) => {
    return (
        <div role="tabpanel">
            {/* Content Sections */}

            <Accordion
                expanded={expanded === faq.id}
                onChange={() => handleChange(faq.id)}
                className="my-4 px-2 lg:px-4 py-2 !rounded-md"
            >
                <AccordionSummary
                    className={` border-cardText ${
                        expanded === faq.id ? "border-b-2" : "border-0"
                    }`}
                    expandIcon={
                        expanded === faq.id ? (
                            <>
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 36 37"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M23.1247 18.8453H12.1252C11.5027 18.8453 11.0002 18.3383 11.0002 17.7137C11.0002 17.089 11.5027 16.582 12.1252 16.582H23.1247C23.7457 16.582 24.2497 17.089 24.2497 17.7137C24.2497 18.3383 23.7457 18.8453 23.1247 18.8453"
                                        fill="#1D417C"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.596 3.77109C6.438 3.77109 3.75 6.63338 3.75 11.0664V24.3895C3.75 28.8225 6.438 31.6848 10.596 31.6848H24.654C28.8135 31.6848 31.5 28.8225 31.5 24.3895V11.0664C31.5 6.63338 28.8135 3.77109 24.654 3.77109H10.596ZM24.654 33.9481H10.596C5.1555 33.9481 1.5 30.1065 1.5 24.3895V11.0664C1.5 5.34934 5.1555 1.50781 10.596 1.50781H24.654C30.0945 1.50781 33.75 5.34934 33.75 11.0664V24.3895C33.75 30.1065 30.0945 33.9481 24.654 33.9481V33.9481Z"
                                        fill="#1D417C"
                                    />
                                </svg>
                            </>
                        ) : (
                            <>
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M17.625 24.2327C17.004 24.2327 16.5 23.7287 16.5 23.1077V12.1172C16.5 11.4962 17.004 10.9922 17.625 10.9922C18.246 10.9922 18.75 11.4962 18.75 12.1172V23.1077C18.75 23.7287 18.246 24.2327 17.625 24.2327"
                                        fill="#1D417C"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M23.1247 18.7344H12.1252C11.5027 18.7344 11.0002 18.2304 11.0002 17.6094C11.0002 16.9884 11.5027 16.4844 12.1252 16.4844H23.1247C23.7457 16.4844 24.2497 16.9884 24.2497 17.6094C24.2497 18.2304 23.7457 18.7344 23.1247 18.7344"
                                        fill="#1D417C"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.596 3.75C6.438 3.75 3.75 6.5955 3.75 11.0025V24.2475C3.75 28.6545 6.438 31.5 10.596 31.5H24.654C28.8135 31.5 31.5 28.6545 31.5 24.2475V11.0025C31.5 6.5955 28.8135 3.75 24.654 3.75H10.596ZM24.654 33.75H10.596C5.1555 33.75 1.5 29.931 1.5 24.2475V11.0025C1.5 5.319 5.1555 1.5 10.596 1.5H24.654C30.0945 1.5 33.75 5.319 33.75 11.0025V24.2475C33.75 29.931 30.0945 33.75 24.654 33.75V33.75Z"
                                        fill="#1D417C"
                                    />
                                </svg>
                            </>
                        )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className="text-lg !font-semibold text-cardText ">
                        {index + 1}: {faq.question}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="!text-sm lg:!text-base">
                        <div
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                        ></div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default QuestionAns
