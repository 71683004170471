//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Strategy } from "../../types/types"

interface State {
    list: Strategy[]
}

const initialState: State = {
    list: [],
}

const slice = createSlice({
    name: "strategy",
    initialState,
    reducers: {
        setStrategy: (state, action: PayloadAction<any>) => {
            return { ...state, list: action.payload }
        },
    },
})

export const { setStrategy } = slice.actions
export const selectStrategy = (state: { strategy: State }) =>
    state.strategy.list
export default slice.reducer
