//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import exp from "constants"

interface State {
    privacyPolicy: string | null
    termCondications: string | null
}

const initialState: State = {
    privacyPolicy: null,
    termCondications: null,
}

const slice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setPrivacyPolicy: (state, action: PayloadAction<any>) => {
            return { ...state, privacyPolicy: action.payload }
        },
        setTermCondications: (state, action: PayloadAction<any>) => {
            return { ...state, termCondications: action.payload }
        },
    },
})

export const { setPrivacyPolicy, setTermCondications } = slice.actions
export const selectSetting = (state: { setting: State }) => state.setting
export const selectPrivacyPolicy = (state: { setting: State }) =>
    state.setting.privacyPolicy
export const selectTermCondications = (state: { setting: State }) =>
    state.setting.termCondications
export default slice.reducer
