import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface UserlistState {
    userlist: any[]
}

const initialState: UserlistState = {
    userlist: [],
}

const userlistSlice = createSlice({
    name: "userlist",
    initialState,
    reducers: {
        setUserlist: (state, action: PayloadAction<any[]>) => {
            return { ...state, userlist: [...action.payload] }
        },
    },
})

export const { setUserlist } = userlistSlice.actions
export const selectUserlist = (state: { userlist: UserlistState }) =>
    state.userlist.userlist
export default userlistSlice.reducer
