export const API_INSTANCE = process.env.REACT_APP_API_INSTANCE
export const BG_REMOVER_API_INSTANCE = process.env.REACT_APP_BG_REMOVER_API
export const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN

type ApiEndpointsType = {
    [key: string]: {
        isAuthRequired: boolean
        url: string
    }
}

export const ApiEndpoints: ApiEndpointsType = {
    UPDATE_EVENT: {
        isAuthRequired: true,
        url: "/event",
    },
    UPLOAD_EVENT_IMAGE: {
        isAuthRequired: true,
        url: "/event/add/image",
    },
    ADD_EVENT_TITLE: {
        isAuthRequired: true,
        url: "/event/title/add",
    },
    CREATE_NEW_VENUE: {
        isAuthRequired: true,
        url: "/event/venue/add",
    },
    CREATE_NEW_ORGANIZER: {
        isAuthRequired: true,
        url: "/event/organizer/add",
    },
    CREATE_NEW_TEAM: {
        isAuthRequired: true,
        url: "/event/team/add",
    },
    DELETE_GUESTS_LIST: {
        isAuthRequired: true,
        url: "/event/guests-list/",
    },
    GET_GUESTS_PAGINATED_LIST: {
        isAuthRequired: true,
        url: "/event/guests-list/list",
    },
    SAVE_GENERATED_IMAGE: {
        isAuthRequired: true,
        url: "/rank/wof/save",
    },
    REMOVE_BACKGROUND: {
        isAuthRequired: true,
        url: "/remove_background",
    },
    GET_WOF_PROFILE_IMAGES: {
        isAuthRequired: true,
        url: "/rank/wof/profiles",
    },
    ACCEPT_PROFILE_IMAGE: {
        isAuthRequired: true,
        url: "/accept",
    },
    GET_WALK_OF_FAME: {
        isAuthRequired: true,
        url: "/rank/wof",
    },
    GET_PARTNERSHIPS: {
        isAuthRequired: true,
        url: "/partnerships/all",
    },
    LOGIN: {
        isAuthRequired: false,
        url: "/auth/login",
    },
    REGISTER: {
        isAuthRequired: false,
        url: "/auth/register",
    },
    VERIFYING_EMAIL: {
        isAuthRequired: false,
        url: "/auth/verify-email",
    },
    RESEND_EMAIL: {
        isAuthRequired: true,
        url: "/auth/resend/register-email",
    },
    CHANGE_PASSWORD: {
        isAuthRequired: true,
        url: "/user/change/password",
    },
    GET_BANK_ACCOUNT_DETAILS: {
        isAuthRequired: true,
        url: "/wallet/addressbook/bank",
    },
    PRIVACY_POLICY: {
        isAuthRequired: false,
        url: "/tools/privacy-policy",
    },
    TERM_CONDITION: {
        isAuthRequired: false,
        url: "/tools/terms-and-conditions",
    },
    ADD_BANK: {
        isAuthRequired: true,
        url: "/wallet/addressbook/add/bank",
    },
    UPDATE_BANK: {
        isAuthRequired: true,
        url: "/wallet/addressbook/edit/bank",
    },
    GUEST_LIST: {
        isAuthRequired: true,
        url: "/event/guests-list/add",
    },
    DUPLICATE_GL_ROW: {
        isAuthRequired: true,
        url: "/event/guests-list/duplicate",
    },
    GET_ADDRESS: {
        isAuthRequired: true,
        url: "/wallet/addressbook",
    },
    DELETE_ADDRESS: {
        isAuthRequired: true,
        url: "/wallet/addressbook/delete",
    },
    CHANGE_EMAIL: {
        isAuthRequired: true,
        url: "/user/change/email",
    },
    GET_CATEGORY: {
        isAuthRequired: true,
        url: "/event/guests-list/categories",
    },
    GET_NETWORK: {
        isAuthRequired: true,
        url: "/wallet/crypto/networks",
    },
    RESET_OTP: {
        isAuthRequired: true,
        url: "/user/kyc/reset",
    },
    RESET_BANK: {
        isAuthRequired: true,
        url: "/wallet/addressbook/add/bank",
    },
    VERIFY_GOOGLE2FA: {
        isAuthRequired: true,
        url: "user/verify-google-2fa",
    },
    GET_PROFILE: {
        isAuthRequired: true,
        url: "/user/profile",
    },
    UPDATE_PROFILE: {
        isAuthRequired: true,
        url: "/user/profile",
    },
    UPDATE_PROFILE_PICTURE: {
        isAuthRequired: true,
        url: "/user/upload-profile-picture",
    },
    UPLOAD_SIGNATURE: {
        isAuthRequired: true,
        url: "/user/upload-signature",
    },
    ADD_CRYPTO: {
        isAuthRequired: true,
        url: "/wallet/addressbook/add/crypto",
    },
    UPDATE_CRYPTO: {
        isAuthRequired: true,
        url: "/wallet/addressbook/edit/crypto",
    },
    RECENT_DEVICES: {
        isAuthRequired: true,
        url: "/user/settings/recent-devices",
    },
    GETPAGINATION: {
        isAuthRequired: true,
        url: "event/guests-list/list",
    },
    LIST_QUESTIONS: {
        isAuthRequired: false,
        url: "/auth/no-ref-questions",
    },
    FORGET_PASSWORD: {
        isAuthRequired: false,
        url: "/auth/forgot-password",
    },
    FORGET_PASSWORD_REQUEST: {
        isAuthRequired: false,
        url: "/auth/forgot-password?email=",
    },
    CHECK_USER: {
        isAuthRequired: false,
        url: "/auth/check/user",
    },
    CHECK_REF_ID: {
        isAuthRequired: false,
        url: "/auth/get-ref-user",
    },
    KYC_CREATE_CLIENT: {
        isAuthRequired: true,
        url: "/user/kyc/create",
    },
    COMPLYCUBE_CHECKS: {
        isAuthRequired: true,
        url: "/user/kyc/comply-cube/checks",
    },
    SET_USER_TERMS: {
        isAuthRequired: true,
        url: "/user/set-user-terms",
    },
    USER_INFO: {
        isAuthRequired: true,
        url: "/user/info",
    },
    GENERATE_GOOGLE_2FA: {
        isAuthRequired: true,
        url: "/user/google-2fa",
    },
    FUNDS_DEPOSIT: {
        isAuthRequired: true,
        url: "/wallet/deposit-funds",
    },
    WALLET_FUNDS: {
        isAuthRequired: true,
        url: "/wallet/funds",
    },
    WALLET_TRANSCATION_HISTORY: {
        isAuthRequired: true,
        url: "/wallet/history",
    },
    WALLET_ADDRESS_LIST: {
        isAuthRequired: true,
        url: "/wallet/funds/withdraw/addressbook",
    },
    WALLET_WITHDRAW: {
        isAuthRequired: true,
        url: "/wallet/funds/withdraw",
    },
    WALLET_BANKS_DETAILS: {
        isAuthRequired: true,
        url: "/wallet/bank/company/accounts",
    },
    STORE_PRODUCTS: {
        isAuthRequired: true,
        url: "/store/products",
    },
    STORE_PRODUCTS_BUY: {
        isAuthRequired: true,
        url: "/store/license/buy",
    },
    STORE_PRODUCT_AUTORENEW: {
        isAuthRequired: true,
        url: "/store/license/auto-renew",
    },
    STRATEGY_LIST: {
        isAuthRequired: true,
        url: "/store/strategy",
    },
    ACTIVE_STRATEGY: {
        isAuthRequired: true,
        url: "/store/strategy/buy",
    },
    COMMUNITY_LIST: {
        isAuthRequired: true,
        url: "/user/community",
    },
    COMMUNITY_MEMBER_DETAILS: {
        isAuthRequired: true,
        url: "/user/community/member/detail",
    },
    COMMUNITY_MEMBER_OVERVIEW: {
        isAuthRequired: true,
        url: "/user/community/overview",
    },
    COMMUNITY_DIRECT_MEMBERS: {
        isAuthRequired: true,
        url: "/auth/direct-users",
    },
    COMMUNITY_ADD_DIRECT_MEMBERS: {
        isAuthRequired: true,
        url: "/auth/add-user",
    },
    WALLET_INFORMATION: {
        isAuthRequired: true,
        url: "/wallet/funds",
    },
    WALLET_INTERNAL_TRANSFER: {
        isAuthRequired: true,
        url: "/wallet/transfer-funds/internal",
    },
    WALLET_INTERNAL_TRANSFER_HISTORY: {
        isAuthRequired: true,
        url: "/wallet/transfer-funds/internal/history",
    },
    WALLET_EXTERNAL_TRANSFER: {
        isAuthRequired: true,
        url: "/wallet/transfer-funds/external",
    },
    WALLET_EXTERNAL_TRANSFER_HISTORY: {
        isAuthRequired: true,
        url: "/wallet/transfer-funds/external/history",
    },
    WALLET_CHART_DATA: {
        isAuthRequired: true,
        url: "/wallet/funds-chart",
    },
    WALLET_WITHDRAW_HISTORY: {
        isAuthRequired: true,
        url: "/wallet/withdrawal-funds/history",
    },
    WALLET_DEPOSIT_HISTORY: {
        isAuthRequired: true,
        url: "/wallet/deposit-funds/history",
    },
    WALLET_TRANSACTION_FEE: {
        isAuthRequired: true,
        url: "/wallet/transaction-fees",
    },
    STORE_BUY_LICENSE: {
        isAuthRequired: true,
        url: "/store/products",
    },
    STORE_GENERATE_COUPONS: {
        isAuthRequired: true,
        url: "/store/coupon/generate",
    },
    STORE_COUPON_TRANSFER: {
        isAuthRequired: true,
        url: "/store/coupon/transfer",
    },
    STORE_COUPON_REDEEM: {
        isAuthRequired: true,
        url: "/store/coupon/redeem",
    },
    STORE_GET_LICNESE_PURCHASE_HISTORY: {
        isAuthRequired: true,
        url: "/store/license/history",
    },
    STORE_NEW_REQUEST: {
        isAuthRequired: true,
        url: "/store/test-unilivel-bonus",
    },
    COUPONS_LIST: {
        isAuthRequired: true,
        url: "/store/coupons",
    },
    ACTIVE_COUPONS_LIST: {
        isAuthRequired: true,
        url: "/store/coupons-active",
    },
    RANK_HISTORY: {
        isAuthRequired: true,
        url: "/user/rank/history",
    },
    OPEN_TICKET: {
        isAuthRequired: true,
        url: "/user/tickets/open",
    },
    GET_TUTORIAL_LIST: {
        isAuthRequired: true,
        url: "/tools/tutorials",
    },
    GET_EVENTS_LIST: {
        isAuthRequired: true,
        url: "/event/list",
    },
    DUPLICATE_EVENT: {
        isAuthRequired: true,
        url: "/event/duplicate",
    },
    DELETE_EVENT: {
        isAuthRequired: true,
        url: "/event/",
    },
    EVENTS_CALENDAR: {
        isAuthRequired: true,
        url: "/event/calendar",
    },
    GET_NOTIFICATIONS: {
        isAuthRequired: true,
        url: "/notifications/all",
    },
    READ_NOTIFICATIONS: {
        isAuthRequired: true,
        url: "/notifications",
    },
    DELETE_NOTIFICATIONS: {
        isAuthRequired: true,
        url: "/notifications/delete/",
    },
    READ_ALL_NOTIFICATIONS: {
        isAuthRequired: true,
        url: "/notifications/mark-all-read",
    },
    NOTIFICATIONS_BADGE: {
        isAuthRequired: true,
        url: "/notifications/badge",
    },
    EVENT_ITEM: {
        isAuthRequired: true,
        url: "/event/",
    },
    ADD_EVENT: {
        isAuthRequired: true,
        url: "/event/add",
    },
    RANK_OVERVIEW: {
        isAuthRequired: true,
        url: "/user/rank/overview",
    },
    REFERRAL_ACTIVITY_HISTORY: {
        isAuthRequired: true,
        url: "/user/members-activity",
    },
    REFERRAL_ANALYTICS: {
        isAuthRequired: true,
        url: "/user/community/ref-analytics",
    },
    SHARE_EMAILS: {
        isAuthRequired: true,
        url: "/user/share/invite",
    },
    SUPPORT_LIST: {
        isAuthRequired: true,
        url: "/user/tickets",
    },
    GET_CATEGORIES: {
        isAuthRequired: true,
        url: "/tools/support-category",
    },
    GET_FAQ: {
        isAuthRequired: true,
        url: "/tools/faq?category=",
    },
    DELETE_SUPPORT_ROWS: {
        isAuthRequired: true,
        url: "/user/tickets/delete",
    },
    DELETE_ROWS: {
        isAuthRequired: true,
        url: "/event/guests-list",
    },
    OPEN_NEW_TICKET: {
        isAuthRequired: true,
        url: "/user/tickets/open",
    },
    TICKET_CATEGORY_LIST: {
        isAuthRequired: true,
        url: "/tools/support-category",
    },
    EVENT_PREINFO: {
        isAuthRequired: true,
        url: "/event/pre-info",
    },
    TICKET_CONVERSATIONS_LIST: {
        isAuthRequired: true,
        url: "/user/tickets/conversation",
    },
    TICKET_CONVERSATIONS_REPLY: {
        isAuthRequired: true,
        url: "/user/tickets/reply",
    },
    CONTRACT_LIST: {
        isAuthRequired: true,
        url: "/tools/contracts",
    },
    KYC_COMPLY_CUBE: {
        isAuthRequired: true,
        url: "/user/kyc/comply-cube/checks",
    },
    RANK_RULES: {
        isAuthRequired: true,
        url: "/user/rank/rules",
    },
    IB_RULES: {
        isAuthRequired: true,
        url: "/user/ib/rules",
    },
    IB_VOLUME: {
        isAuthRequired: true,
        url: "/user/ib/deposits",
    },
    IB_PERCENTAGES: {
        isAuthRequired: true,
        url: "/user/ib/percentages",
    },
    NEWS_LIST: {
        isAuthRequired: true,
        url: "/tools/news",
    },
    RANK_COMMISSION_BONUSES: {
        isAuthRequired: true,
        url: "/commissions/bonuses",
    },
    RANK_COMMISSION_CHARTS: {
        isAuthRequired: true,
        url: "/commissions/chart",
    },
    IB_BONUSES_DATA: {
        isAuthRequired: true,
        url: "/commissions/ib/bonuses",
    },
    RANK_PERCENTAGES: {
        isAuthRequired: true,
        url: "/user/rank/percentages",
    },
    BONUS_HISTORY: {
        isAuthRequired: true,
        url: "/commissions/history",
    },
    BONUS_HISTORY_IB: {
        isAuthRequired: true,
        url: "/commissions/ib/history",
    },
    USER_LIST: {
        isAuthRequired: true,
        url: "/store/coupon/users",
    },
    COUPON_TRANSFER: {
        isAuthRequired: true,
        url: "/store/coupon/transfer",
    },
    STEPPER: {
        isAuthRequired: true,
        url: "/user/onboarding-steps",
    },
    STEPPER_ACCOUNT: {
        isAuthRequired: true,
        url: "/user/set-ktm",
    },
    STRATEGY_ANIMATION: {
        isAuthRequired: true,
        url: "tools/strategy/percentage",
    },
    UPDATE_USER_PROFILE: {
        isAuthRequired: true,
        url: "user/set-profile",
    },
    ACTIVATE_STRATEGY: {
        isAuthRequired: true,
        url: "user/activate-strategy",
    },
    IMPERSONATE_LOGIN: {
        isAuthRequired: false,
        url: "bp/admin/impersonate",
    },
}

export const TOOLS_BASE_SERVER = "https://tools.blpro8.club"
export const CHECK_USER_API: string = TOOLS_BASE_SERVER + "/check-broker-user"
export const ACTIVE_STRATEGY = TOOLS_BASE_SERVER + "/active-strategy"
export const BROKER_ACCOUNT_REGISTRATION: string =
    TOOLS_BASE_SERVER + "/register-broker"
