import { useEffect, useRef } from "react"

const Coverflow = ({
    list_rank,
    CENTER_ITEM_DISTANCE = 10,
    ITEM_DISTANCE = 220,
    CENTER_ITEM_POP = 220,
    cardsClass = "w-[350px] h-[350px]",
    imgClass = "",
    width = "100%",
    height = "400px",
    selectedIndex = Math.floor(list_rank.length * 0.5),
    isNonAchivedSelecteable,
    callBack = (e: any, item: any, index: number) => {},
}) => {
    const el = useRef<HTMLDivElement>(null)

    const ITEM_ANGLE = -45

    // Help function to set element style transform property
    function setTransform(
        el: HTMLDivElement,
        xpos: number,
        zpos: number,
        yAngle: number
    ) {
        el.style.transform = `translateX(${xpos}px) translateZ(${zpos}px) rotateY(${yAngle}deg)`
    }
    useEffect(() => {
        // target(Math.floor(imageData.length * 0.5))
        target(selectedIndex)
    }, [])

    // Target an item, bring it to center
    function target(index: number) {
        const items = el.current!.children

        for (let i = 0; i < items.length; i++) {
            const item = items[i] as HTMLDivElement

            // Center item position and angle
            if (i == index) setTransform(item, 0, CENTER_ITEM_POP, 0)
            // Left items position and angle
            else if (i < index) {
                setTransform(
                    item,
                    (i - index) * ITEM_DISTANCE - CENTER_ITEM_DISTANCE,
                    0,
                    -ITEM_ANGLE
                )
            }
            // Right items position and angle
            else
                setTransform(
                    item,
                    (i - index) * ITEM_DISTANCE + CENTER_ITEM_DISTANCE,
                    0,
                    ITEM_ANGLE
                )
        }
    }

    return (
        <div
            className="container__for__slider my-4 font-montserrat text-sm font-semibold flex"
            style={{ width: width, height: height }}
        >
            <div
                className="coverflow__for__slider"
                ref={el}
                style={{
                    top: "50%",
                    left:
                        list_rank.length >= 7
                            ? selectedIndex === 0
                                ? "20%"
                                : "50%"
                            : "50%",
                }}
            >
                {list_rank.map((rank: any, index: number) => {
                    const background_color =
                        rank && rank.active
                            ? "bg-gradient-to-r from-[#1d447c] to-[#081732]"
                            : "bg-[#ededed]"

                    return (
                        <div
                            key={index}
                            onClick={(e: any) => {
                                if (!isNonAchivedSelecteable && !rank.active)
                                    return
                                target(index)
                                callBack(e, rank, index)
                            }}
                            className={`coverflow-item__for__slider flex justify-center items-center 
                                    rounded-3xl ${background_color} ${cardsClass}`}
                            // style={{ backgroundImage: `url(${it})` }}
                        >
                            <div className="flex justify-between flex-col gap-1 items-center">
                                <img
                                    src={rank.icon}
                                    alt="rank icon"
                                    className={`h-[220px] w-[220px] ${imgClass}`}
                                />
                                <span
                                    className={`${
                                        rank.active
                                            ? `text-white`
                                            : `text-cardText`
                                    } text-center ${
                                        rank.selectedIndex === index &&
                                        `text-base font-bold`
                                    }`}
                                >
                                    {rank.name}
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Coverflow
