import { createSlice, PayloadAction } from "@reduxjs/toolkit"
//UTILS
import currentStorage from "../../utils/currentStorage"

interface UserSliceState {
    accessToken: string
    refreshToken: string
    loading: boolean
    user: any
    profile?: any
}

const initialState: UserSliceState = {
    accessToken: currentStorage().getItem("accessToken") || "",
    refreshToken: currentStorage().getItem("refreshToken") || "",
    loading: false,
    user: currentStorage().getItem("user")
        ? JSON.parse(currentStorage().getItem("user") || "{}")
        : null,
    profile: null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLogin: (state, action: PayloadAction<any>) => {
            const storage = currentStorage(action.payload.rememberMe)
            storage.setItem("accessToken", action.payload.accessToken)
            storage.setItem("refreshToken", action.payload.refreshToken)
            storage.setItem("user", JSON.stringify(action.payload.user))
            return { ...state, ...action.payload }
        },
        setLogout: () => {
            const storage = currentStorage()
            storage.removeItem("accessToken")
            storage.removeItem("refreshToken")
            storage.removeItem("user")
            return {
                accessToken: storage.getItem("accessToken") || "",
                refreshToken: storage.getItem("refreshToken") || "",
                loading: false,
                user: storage.getItem("user")
                    ? JSON.parse(storage.getItem("user") || "{}")
                    : null,
                profile: null,
            }
        },
        setUpdateUser: (state, action: PayloadAction<{ user: any }>) => {
            const storage = currentStorage()
            storage.setItem("user", JSON.stringify(action.payload.user))
            return { ...state, user: action.payload.user }
        },
        setProfile: (state, action: PayloadAction<{ profile: any }>) => {
            return { ...state, profile: action.payload.profile }
        },
        setProfileLoading: (state) => {
            return { ...state, loading: true }
        },
    },
})

export const {
    setLogin,
    setLogout,
    setUpdateUser,
    setProfile,
    setProfileLoading,
} = userSlice.actions

export const selectUserSlice = (state: { user: UserSliceState }) => state.user

export const selectAccessToken = (state: { user: UserSliceState }) =>
    state.user.accessToken

export const selectRefreshToken = (state: { user: UserSliceState }) =>
    state.user.refreshToken

export const selectUser = (state: { user: UserSliceState }) => state.user.user

export const selectProfile = (state: { user: UserSliceState }) =>
    state.user.profile
export const selectLoading = (state: { user: UserSliceState }) =>
    state.user.loading
export default userSlice.reducer
