import React from "react"

/* Dashboard */
export const CompletionSteps = React.lazy(() => import("./CompletionSteps"))
export const DateRangeFilter = React.lazy(() => import("./DateRangeFilter"))
export const Profile = React.lazy(() => import("./Dashboard/Profile"))
export const Activity = React.lazy(() => import("./Dashboard/Activity"))
export const BalanceDetails = React.lazy(
    () => import("./Dashboard/BalanceDetails")
)
export const LatestNews = React.lazy(() => import("./Dashboard/LatestNews"))
export { default as ProfileComponents } from "./Dashboard/ProfileComponents"
export const CommunityChart = React.lazy(
    () => import("./Dashboard/CommunityChart")
)
export const MapCard = React.lazy(() => import("./Dashboard/MapCard"))
/* Layout */
export const Layout = React.lazy(() => import("./Layout"))
export const AuthLayout = React.lazy(() => import("./AuthLayout"))

/* Charts */
export const PieChart = React.lazy(() => import("./Charts/PieChart"))
export const BumpChart = React.lazy(() => import("./Charts/BumpChart"))
export const PieCustom = React.lazy(() => import("./Charts/PieCustom"))
export const GraphChart = React.lazy(() => import("./Charts/GraphChart"))

/* Modules */
export const ResponsiveTable = React.lazy(
    () => import("./Table/ResponsiveTable")
)

//table check boxes
export { default as CheckBoxes } from "./Table/CheckBoxes"

/* WALLET CARD */
export const WalletCard = React.lazy(() => import("./WalletCards/WalletCard"))
export const WalletCards = React.lazy(() => import("./WalletCards/WalletCards"))

/* Button export */
export const MlmButton = React.lazy(() => import("./Mlm-Button/MlmButton"))
export const SocialButton = React.lazy(
    () => import("./Mlm-Button/SocialButton")
)
export const DepositHistory = React.lazy(
    () => import("./DepositHistory/DepositHistory")
)
export const DropDown = React.lazy(() => import("./DropDown/DropDown"))
export const DropDownMenu = React.lazy(() => import("./DropDown/DropDownMenu"))
export const MlmRadioButton = React.lazy(
    () => import("./Mlm-RadioButton/MlmRadioButton")
)
export const QR = React.lazy(() => import("./QRGenerator/QR"))
export const BtnClipboard = React.lazy(
    () => import("./Btn-Clipboard/BtnClipboard")
)

// two column format export
export const TwoColumn = React.lazy(() => import("./2-Column/TwoColumn"))

// rank components
export { default as RankStatusCard } from "./Rank-Component/Rank_Status_Card"

export const RankGraphicsCard = React.lazy(
    () => import("./Rank-Component/Graphics_Cards")
)

export const RankOverViewCard = React.lazy(
    () => import("./Rank-Component/Rank_Overview_Card")
)
export const RankCoverFlow = React.lazy(
    () => import("./Rank-Component/Rank_CoverFlow")
)

// DIALOGS
export const ParentDialog = React.lazy(() => import("./Dialogs/ParentDialog"))
export const ParentDialogLG = React.lazy(
    () => import("./Dialogs/ParentDialogLG")
)

// CONTENTS
export { default as QRShareContent } from "./Dialogs/Contents/QRShareContent"

// store dialogs
export const CheckoutDialog = React.lazy(
    () => import("../components/storeDialogs/CheckoutDialog")
)
export const DetailsDialog = React.lazy(
    () => import("../components/storeDialogs/DetailsDialog")
)
export const WalletDialog = React.lazy(
    () => import("../components/storeDialogs/WalletDialog")
)
export const DepositDialog = React.lazy(
    () => import("../components/storeDialogs/DepositDialog")
)

// COMMUNITY MEMBERS
export const ListingMembers = React.lazy(
    () => import("./community/Listing-member")
)
export const ListingMemberDetails = React.lazy(
    () => import("./community/Listing-member-details")
)
export const AddUser = React.lazy(() => import("./community/AddUser"))
//Progress
export { default as LineProgress } from "./Progress/LineProgress"
//Calendar Events
export const CalendarEvents = React.lazy(
    () => import("./Dashboard/CalendarEvents")
)
//OTP
export const OTP = React.lazy(() => import("./OTP-Popup/OTP"))

//PreLoader
export { default as PreLoader } from "./PreLoader"

//PDF GENERATOR AND THEN DOWNLOAD
export const PDFGenerator = React.lazy(
    () => import("./PdfGenerator/PDFGenerator")
)
export const PDFLink = React.lazy(() => import("./PdfGenerator/PDFLink"))
// CURRENCIES
export const EuroCurrency = React.lazy(
    () => import("./currencies/EuroCurrency")
)
export const CurrencyFormat = React.lazy(
    () => import("./currencies/CurrencyFormat")
)

// BANK DEPOSIT COMPONENT
export const BankDeposit = React.lazy(
    () => import("./DepositHistory/BankDeposit")
)

// DROP ITEMS
export const DropItem = React.lazy(() => import("./DropItem/DropItem"))
//INPUT AUTOCOMPLETE
export const InputDrop = React.lazy(() => import("./DropDown/InputDrop"))
//INPUT AUTOCOMPLETE
export const DynamicDiamonds = React.lazy(
    () => import("./Rank-Component/DynamicDiamonds")
)

export const RuntimeCheck = React.lazy(() => import("./RuntimeCheck"))
//strategy dialog
export const StrategyKeyTo = React.lazy(
    () => import("./storeDialogs/StrategyKeyTo")
)
//news
export const News = React.lazy(() => import("./Dashboard/News"))

//coupons
export const CouponList = React.lazy(() => import("./store/coupons/CouponList"))
//products
export const ProductCard = React.lazy(
    () => import("./store/Products/ProductCard")
)
//RENEW SWETICHS
export const Switchs = React.lazy(() => import("./Switches/Switchs"))
//RENEW SWETICHS
export const AutoRenewCheck = React.lazy(
    () => import("./store/Products/AutoRenewCheck")
)
