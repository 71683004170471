import { copy } from "../../images/other"
import { errorNotify, successNotify } from "../../utils/alerts"

export default function BtnClipboard({
    className = "",
    textClass = "",
    iconClass = "",
    text,
    icon = copy,
    title = "",
}): JSX.Element {
    const copyToClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text)
            successNotify("copied " + text)
        } catch (error) {
            console.error("Failed to copy text:", error)
            errorNotify("Failed to copy")
        }
    }

    return (
        <div
            className={`flex items-center justify-center cursor-pointer ${className}`}
            onClick={() => copyToClipboard(text)}
        >
            {icon && (
                <img
                    src={icon}
                    alt="copy icon"
                    className={`w-[30px] ${iconClass}`}
                />
            )}
            {title && (
                <span
                    className={`font-montserrat text-[12px] font-[600] ${textClass}`}
                >
                    {title}
                </span>
            )}
        </div>
    )
}
