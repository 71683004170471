//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    activityList: any
    analytics: any
}

const initialState: State = {
    activityList: null,
    analytics: null,
}

const slice = createSlice({
    name: "referralLink",
    initialState,
    reducers: {
        setActivityList: (state, action: PayloadAction<any>) => {
            return { ...state, activityList: action.payload }
        },
        setAnalytics: (state, action: PayloadAction<any>) => {
            return { ...state, analytics: action.payload }
        },
    },
})

export const { setActivityList, setAnalytics } = slice.actions

export const selectReferral = (state: { referral: State }) => state.referral

export default slice.reducer
