// REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface PurchaseHistory {
    id: number
    buy_date: string | null
    expiry_date: string | null
    status: "Active" | "Expired"
    product: "Standard License" | "Premium License"
}

interface Coupons {
    code: string
    xname: string
    expiry: string
    active: boolean
    balance: number
    initial_amount: number
}

interface State {
    purchaseHistory: {
        objects: PurchaseHistory[]
        list_pages: any[]
        total_objects: number
        total_pages: number
        has_next: boolean
        page: number
        limit: number
    }
    couponsList: {
        active_coupons: number
        coupons: Coupons[]
    }
    activeCouponsList: any[]
}

const initialState: State = {
    purchaseHistory: {
        objects: [],
        list_pages: [],
        total_objects: 0,
        total_pages: 0,
        has_next: true,
        page: 1,
        limit: 10,
    },
    couponsList: {
        active_coupons: 0,
        coupons: [],
    },
    activeCouponsList: [],
}

const store = createSlice({
    name: "store",
    initialState,
    reducers: {
        setPurchaseHistory: (
            state,
            action: PayloadAction<PurchaseHistory[]>
        ): any => {
            return {
                ...state,
                purchaseHistory: {
                    ...state.purchaseHistory,
                    ...action.payload,
                },
            }
        },
        changePurchasePage: (state, action: PayloadAction<any>): any => {
            if (!state.purchaseHistory.has_next) {
                return {
                    ...state,
                    purchaseHistory: {
                        ...state.purchaseHistory,
                        page: action.payload,
                    },
                }
            }
            return { ...state }
        },
        setCouponsList: (state, action: PayloadAction<any>): any => {
            return {
                ...state,
                couponsList: { ...state.couponsList, ...action.payload },
            }
        },
        setActiveCouponsList: (state, action: PayloadAction<any>): any => {
            return {
                ...state,
                // activeCouponsList: { ...state.activeCouponsList, ...action.payload },
                activeCouponsList: action.payload,
            }
        },
    },
})

export const {
    setPurchaseHistory,
    changePurchasePage,
    setCouponsList,
    setActiveCouponsList,
} = store.actions
export const selectStore = (state: { store: State }) => state.store
export const selectPurchaseHistory = (state: { store: State }) =>
    state.store.purchaseHistory
export const selectCouponsList = (state: { store: State }) =>
    state.store.couponsList
export const selectActiveCouponsList = (state: { store: State }) =>
    state.store.activeCouponsList
export default store.reducer
