//REDUX-TOOLKIT
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface Notification {
    list: []
    badge: number
}

const initialState: Notification = {
    list: [],
    badge: 0,
}

const slice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotificationData: (state, action: PayloadAction<any>) => {
            return { ...state, list: action.payload }
        },
        setBadge: (state, action: PayloadAction<any>) => {
            return { ...state, badge: action.payload }
        },
        removeNotification: (state, action: PayloadAction<any>) => {
            const newArray: any = state.list.filter(
                (item: any) => item.id !== action.payload
            )

            return { ...state, list: newArray }
        },
    },
})

export const { setNotificationData, setBadge, removeNotification } =
    slice.actions
export const selectNotificationData = (state: {
    notifications: Notification
}) => state.notifications.list
export const notification_badge = (state: { notifications: Notification }) =>
    state.notifications.badge
export default slice.reducer
