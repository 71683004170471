import {
    approved,
    enterence,
    forward,
    starLevel,
    rankLevel,
    user_default,
} from "./other"

export const tractor_active = "/img/strategy/tractor_active.svg"
export const tractor_inactive = "/img/strategy/tractor_inactive.svg"
export const tractor2_active = "/img/strategy/tractor2.png"

export const lambo_active = "/img/strategy/lambo_active.svg"
export const lambo_inactive = "/img/strategy/lambo_inactive.svg"
export const hummer_active = "/img/strategy/hummer_active.svg"
export const hummer_inactive = "/img/strategy/hummer_inactive.svg"
export const work_icon = "/img/strategy/work.svg"
export const euro_icon = "/img/strategy/euro_icon.svg"
export const arrange_square = "/img/strategy/arrange-square.svg"

export const search_icon = "/img/other/search.svg"
export const flag_icon = "/img/tutorial/flag.svg"

export const ticket_icon = "/img/support/ticket.svg"
export const ticketBlue_icon = "/img/support/ticket-blue.svg"
export const filter_icon = "/img/support/filter.svg"
export const chat_icon = "/img/support/chat.svg"
export const delete_icon = "/img/support/delete.svg"
export const chatWhite_icon = "/img/support/chat-white.svg"
export const deleteWhite_icon = "/img/support/delete-white.svg"

export const plusWhite_icon = "/img/support/plus-white.svg"
export const closeBlue_icon = "/img/support/close-blue.svg"
export const support_png = "/img/support/support.png"
export const user_png = "/img/support/user.png"
export const clockBlue_icon = "/img/support/clock-blue.svg"

export const calendarIcon = "/img/events/calendar-icon.svg"
export const calendarIcon2 = "/img/events/calendar-icon2.svg"
export const filter = "/img/events/filter-icon.svg"
export const filter_white = "/img/events/filter-icon-white.svg"
export const download = "/img/events/download.svg"
export const download_white = "/img/events/download-white.svg"
export const world = "/img/events/world.svg"
export const lock = "/img/events/lock.svg"
export const threeuser = "/img/events/threeuser.svg"
export const uk = "/img/events/uk.svg"
export const spain = "/img/events/spain.svg"
export const italy = "/img/events/italy.svg"
export const share = "/img/events/share.svg"
export const deleted = "/img/events/delete.svg"
export const copy = "/img/events/copy.svg"
export const edit = "/img/events/edit.svg"
export const computer = "/img/events/computer.svg"
export const led = "/img/events/led.svg"
export const mobile = "/img/events/mobile.svg"
export const guest_icon = "/img/events/guest.svg"

export const BLdummy = "/img/events/bl.png"
export const softDummy = "/img/events/soft.png"
export const quickDummy = "/img/events/quick.png"

export const generalWhite = "/img/events/generalWhite.svg"
export const defaultImage = "/img/events/image.png"

export const screenshot = "/img/tutorial/1.png"
export const tutorialImg1 = "/img/tutorial/tutorial1.png"
export const tutorialImg2 = "/img/tutorial/tutorial2.png"
export const tutorialImg3 = "/img/tutorial/tutorial3.png"
export const tutorialImg4 = "/img/tutorial/tutorial4.png"

//diamonds icons
export const shareDiamondBg1 = "/img/diamonds/share-diamond-bg-1.svg"
//social icons
export const discordIcon = "/img/socials/discord.svg"
//download icon
export const downloadLightIcon = "/img/download/download-light.svg"
//graphs
export const negativeGraphIcon = "/img/graphs/negative-graph.svg"
export const positiveGraphIcon = "/img/graphs/positive-graph.svg"
//clipboard
export const clipboardWhite = "/img/clipboards/clipboard-white.svg"
//community
export const membersIcon = "/img/community/members.svg"
export const kycConfirmedIcon = "/img/community/kyc-confirmed.svg"
export const deviceIcon = "/img/community/device-icon.svg"
export const aCircle = "/img/community/a-circle.svg"
export const communityDirect = "/img/community/community-direct.svg"
export const communitySales = "/img/community/community-sales.svg"
export const communityNewMembers = "/img/community/community-new-members.svg"
export const communityMembers = "/img/community/community-members.svg"
//store
export const questionCircleOutlined = "/img/other/question-circle-outlined.svg"
export const euroBoldIcon = "/img/currency/euro.svg"

//pins
export const AMBASSADOR = "/img/pins/AMBASSADOR.png"
export const CONSULTANT = "/img/pins/CONSULTANT.png"
export const CUSTOMER = "/img/pins/CUSTOMER.png"
export const EXECUTIVE = "/img/pins/EXECUTIVE.png"
export const FOUNDER = "/img/pins/FOUNDER.png"
export const Level0 = "/img/pins/Level-0.svg"
export const Level1 = "/img/pins/Level-1.png"
export const Level2 = "/img/pins/Level-2.png"
export const Level3 = "/img/pins/Level-3.png"
export const Level4 = "/img/pins/Level-4.png"
export const Level5 = "/img/pins/Level-5.png"
export const Level6 = "/img/pins/Level-6.png"
export const Level7 = "/img/pins/Level-7.png"
export const Manager = "/img/pins/MANAGER.png"
export const President = "/img/pins/PRESIDENT.png"
export const KeyToMarketIcon = "/img/KeyToMarkets/KeyToMarket.svg"
export const smallWhiteDiamond = "/img/other/small-white-diamond.svg"

export const POSITION_ICONS = {
    CUSTOMER: CUSTOMER,
    CONSULTANT: CONSULTANT,
    EXECUTIVE: EXECUTIVE,
    MANAGER: Manager,
    AMBASSADOR: AMBASSADOR,
    FOUNDER: FOUNDER,
    PRESIDENT: President,
}

export const ACTIVITY_ICONS = {
    IB_LEVEL: starLevel,
    INVITED: enterence,
    RANK: rankLevel,
    KYC: approved,
    JOINED: forward,
    PREMIUM_LICENSE: user_default,
    STANDARD_LICENSE: user_default,
}

export const LEVELS = {
    LEVEL0: Level0,
    LEVEL1: Level1,
    LEVEL2: Level2,
    LEVEL3: Level3,
    LEVEL4: Level4,
    LEVEL5: Level5,
    LEVEL6: Level6,
    LEVEL7: Level7,
}

export const white_screen = "/img/others/white_screen"
