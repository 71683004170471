//REACT
import React from "react"
//ROUTER-DOM
import { createBrowserRouter } from "react-router-dom"
//COMPONENTS
import { Layout, AuthLayout, PreLoader } from "../components"
//PAGES
import {
    AcceptTnC,
    Settings,
    Contract,
    CreateNewPassword,
    Error,
    Faq,
    ForgotPassword,
    Home,
    InsertResidentRetail,
    SignatureDraw,
    KYCRejected,
    Login,
    PendingApproval,
    PendingKYCProcedure,
    PendingRegistration,
    PrivacyPolicy,
    Questions,
    QuestionSent,
    Overview,
    Commissions,
    Rules,
    ReferralLink,
    Signup,
    UpdateYourProfile,
    StartYourKYC,
    Overview_,
    Products,
    Strategies,
    Coupons,
    PurchaseHistory,
    // CommunityPage,
    CommunityPageNew,
    SuccessVerifyEmail,
    Support,
    TermsAndCondition,
    Tutorials,
    UploadYourDocument,
    VerifyEmail,
    WalletsOverview,
    WalletsTransfer,
    WalletsWithdraw,
    AddressBook,
    DepositPage,
    DepositDetailsPage,
    DepositCryptoDetailsPage,
    Logout,
    WalletsInternalExternalTransfer,
    NewTicket,
    Conversation,
    Reply,
    Notifications,
    GenerateCoupons,
    EventListPage,
    EventAddPage,
    GuestListPage,
    GuestAddPage,
    VerifyingEmail,
    NFT,
    TranscationStatus,
    TransactionHistory,
    News,
    Activities,
    Impersonate,
    Bonuses,
    Partnerships,
} from "../pages"
import KeyToMarkets from "../pages/KeyToMarkets"

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <React.Suspense fallback={<PreLoader />}>
                <Layout />
            </React.Suspense>
        ),
        children: [
            {
                path: "/",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Home />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallet/view/:title",
                element: <TransactionHistory />,
            },

            {
                path: "/wallets/deposit",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <DepositPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/deposit/details",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <DepositDetailsPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/deposit/crypto/details",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <DepositCryptoDetailsPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/overview",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <WalletsOverview />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/withdraw",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <WalletsWithdraw />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/withdraw/details",
                element: (
                    <React.Suspense fallback={<></>}>
                        <DepositDetailsPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/addressbook",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <AddressBook onClickEdit={() => {}} />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/transfer",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <WalletsTransfer />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/transfer/wallet",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <WalletsInternalExternalTransfer />
                    </React.Suspense>
                ),
            },
            {
                path: "/wallets/transfer/user",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <WalletsInternalExternalTransfer />
                    </React.Suspense>
                ),
            },
            {
                path: "/store/overview",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Overview_ />
                    </React.Suspense>
                ),
            },
            {
                path: "/store/products",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Products />
                    </React.Suspense>
                ),
            },
            {
                path: "/store/strategies",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Strategies />
                    </React.Suspense>
                ),
            },
            {
                path: "/store/coupons",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Coupons />
                    </React.Suspense>
                ),
            },
            {
                path: "/store/purchase-history",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <PurchaseHistory />
                    </React.Suspense>
                ),
            },
            {
                path: "/store/NFT",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <NFT />
                    </React.Suspense>
                ),
            },
            {
                path: "/community",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <CommunityPageNew />
                        {/* <CommunityPage /> */}
                    </React.Suspense>
                ),
            },
            {
                path: "/rank/overview",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Overview />
                    </React.Suspense>
                ),
            },
            {
                path: "/rank/commissions",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Commissions />
                    </React.Suspense>
                ),
            },
            {
                path: "/rank/bonuses",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Bonuses />
                    </React.Suspense>
                ),
            },
            {
                path: "/rank/rules",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Rules />
                    </React.Suspense>
                ),
            },
            {
                path: "/referral-link",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <ReferralLink />
                    </React.Suspense>
                ),
            },
            {
                path: "/events",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <EventListPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/events/add",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <EventAddPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/events/edit/:id",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <EventAddPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/events/guest-list",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <GuestListPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/events/guest-add",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <GuestAddPage />
                    </React.Suspense>
                ),
            },
            {
                path: "/contract",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Contract />
                    </React.Suspense>
                ),
            },
            {
                path: "/terms-and-condition",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <TermsAndCondition />
                    </React.Suspense>
                ),
            },
            {
                path: "/privacy-policy",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <PrivacyPolicy />
                    </React.Suspense>
                ),
            },
            {
                path: "/faq",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Faq />
                    </React.Suspense>
                ),
            },
            {
                path: "/tutorials",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Tutorials />
                    </React.Suspense>
                ),
            },
            {
                path: "/partnerships",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Partnerships />
                    </React.Suspense>
                ),
            },
            {
                path: "/partnerships/:id",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <KeyToMarkets />
                    </React.Suspense>
                ),
            },
            {
                path: "/support",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Support />
                    </React.Suspense>
                ),
            },
            {
                path: "/support/open-new-ticket",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <NewTicket />
                    </React.Suspense>
                ),
            },
            {
                path: "/support/conversation",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Conversation />
                    </React.Suspense>
                ),
            },
            {
                path: "/support/conversation/reply",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Reply />
                    </React.Suspense>
                ),
            },
            {
                path: "/notifications",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Notifications />
                    </React.Suspense>
                ),
            },
            {
                path: "/insert-resident-retail",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <InsertResidentRetail />
                    </React.Suspense>
                ),
            },
            {
                path: "/pending-approval",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <PendingApproval />
                    </React.Suspense>
                ),
            },
            {
                path: "/settings",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Settings />
                    </React.Suspense>
                ),
            },
            // logout
            {
                path: "/logout",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Logout />
                    </React.Suspense>
                ),
            },
            // extra
            {
                path: "/generate-coupons",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <GenerateCoupons />
                    </React.Suspense>
                ),
            },
            {
                path: "/transaction/status",
                element: (
                    <React.Suspense fallback={<></>}>
                        <TranscationStatus />
                    </React.Suspense>
                ),
            },
            {
                path: "/latest-news",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <News />
                    </React.Suspense>
                ),
            },
            {
                path: "/activities",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Activities />
                    </React.Suspense>
                ),
            },
        ],
    },
    {
        path: "/kyc",
        element: (
            <React.Suspense fallback={<PreLoader />}>
                <AuthLayout />
            </React.Suspense>
        ),
        children: [
            {
                path: "pending-registration",
                element: (
                    <React.Suspense fallback={<></>}>
                        <PendingRegistration />
                    </React.Suspense>
                ),
            },
            {
                path: "accept-terms-condition",
                element: (
                    <React.Suspense fallback={<></>}>
                        <AcceptTnC />
                    </React.Suspense>
                ),
            },
            {
                path: "start-your-kyc",
                element: (
                    <React.Suspense fallback={<></>}>
                        <StartYourKYC />
                    </React.Suspense>
                ),
            },
            {
                path: "pending-kyc-procedure",
                element: (
                    <React.Suspense fallback={<></>}>
                        <PendingKYCProcedure />
                    </React.Suspense>
                ),
            },
            {
                path: "kyc-rejected",
                element: (
                    <React.Suspense fallback={<></>}>
                        <KYCRejected />
                    </React.Suspense>
                ),
            },
            {
                path: "signature-draw",
                element: (
                    <React.Suspense fallback={<></>}>
                        <SignatureDraw />
                    </React.Suspense>
                ),
            },
            {
                path: "upload-your-document",
                element: (
                    <React.Suspense fallback={<></>}>
                        <UploadYourDocument />
                    </React.Suspense>
                ),
            },
            {
                path: "update-your-profile",
                element: (
                    <React.Suspense fallback={<></>}>
                        <UpdateYourProfile />
                    </React.Suspense>
                ),
            },
        ],
    },
    {
        path: "/auth",
        element: (
            <React.Suspense fallback={<PreLoader />}>
                <AuthLayout />
            </React.Suspense>
        ),
        children: [
            // login
            {
                path: "login",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <Login />
                    </React.Suspense>
                ),
            },
            // signup
            {
                path: "signup",
                element: (
                    <React.Suspense fallback={<></>}>
                        <Signup />
                    </React.Suspense>
                ),
            },
            {
                path: "signup/questions",
                element: (
                    <React.Suspense fallback={<></>}>
                        <Questions />
                    </React.Suspense>
                ),
            },
            {
                path: "signup/question-sent",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <QuestionSent />
                    </React.Suspense>
                ),
            },
            {
                path: "signup/verify-email",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <VerifyEmail />
                    </React.Suspense>
                ),
            },
            {
                path: "signup/success-verify-email",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <SuccessVerifyEmail />
                    </React.Suspense>
                ),
            },
            // forgot-password
            {
                path: "forgot-password",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <ForgotPassword />
                    </React.Suspense>
                ),
            },
            {
                path: "forgot-password/create-new-password/:email/:token",
                element: (
                    <React.Suspense fallback={<PreLoader />}>
                        <CreateNewPassword />
                    </React.Suspense>
                ),
            },
        ],
    },
    {
        path: "/verify-email",
        element: (
            <React.Suspense fallback={<PreLoader />}>
                <VerifyingEmail />
            </React.Suspense>
        ),
    },
    {
        path: "/impersonate",
        element: (
            <React.Suspense fallback={<PreLoader />}>
                <Impersonate />
            </React.Suspense>
        ),
    },
    {
        path: "*",
        element: (
            <React.Suspense fallback={<PreLoader />}>
                <Error />
            </React.Suspense>
        ),
    },
])
